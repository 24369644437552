import React, { useEffect, useRef, useState } from "react";
import "./CustomDropdown.scss";
import { ReactComponent as ArrowRight } from "../../assets/svg/ArrowRIght.svg";
import { ReactComponent as PencilIcon } from "../../assets/svg/pencil.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrowDown.svg";
import { ReactComponent as Cross } from "assets/svg/close.svg";
import { PlusOutlined } from "@ant-design/icons";
import { unitsData } from "../../scenes/ProjectPlanner/components/GoalSettings/data";
import { Button, Modal, Row } from "antd";
import ToggleMenu from "../Togglemenu/ToggleMenu";
import { getCustomUnitName } from "../../scenes/ProjectPlanner/components/GoalSettings/utils";
import { useDispatch, useSelector } from "react-redux";
import { blukUpdateGoal } from "../../modules/actions/PlanActions";

const CustomDropdown = ({ planDetails, handleUpdateGoal, handleChange }) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const submenuRef = useRef(null);

  const { loading } = useSelector((state) => state.plan.plan);

  const [customItems, setCustomItems] = useState();

  const [selectedUnit, setSelectedUnit] = useState(null);

  const [scrollValues, setScrollValues] = useState({
    scrollTop: 0,
    scrollLeft: 0,
  });

  const [isMenu, setIsMenu] = useState(false);
  const [submenuData, setSubmenuData] = useState(null);
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(true);
  const [deletedItems, setDeletedItems] = useState([]);
  const [deletedUnits, setDeletedUnits] = useState([]);
  const [errorItems, setErrorItems] = useState([]);
  const [manageCustomUnit, setManageCustomUnit] = useState(false);
  const [submenuPosition, setSubmenuPosition] = useState({
    top: -3000,
    left: -3000,
  });

  const handleMouseEnter = (event, data, isCustom) => {
    if (data?.length === 0) return;
    const rect = event.currentTarget.getBoundingClientRect();

    let calculatedTop = rect.top + scrollValues.scrollTop - 97;

    const sortData = isCustom ? [...data].sort((a, b) => a.id - b.id) : data;

    setSubmenuData(sortData);
    setSubmenuPosition({
      top: calculatedTop,
      left: rect.left + 50 + scrollValues.scrollLeft,
    });
    setIsSubmenuVisible(true);
  };

  const handleMouseLeave = () => {
    setIsSubmenuVisible(false);
  };

  const handleCloseEditModel = () => {
    setManageCustomUnit(false);
    setDeletedItems([]);
    setErrorItems([]);
    setDeletedUnits([]);
  };

  useEffect(() => {
    const scrollableElement = document.getElementById("scrollableDiv");

    const handleScroll = () => {
      setScrollValues({
        scrollTop: scrollableElement.scrollTop,
        scrollLeft: scrollableElement.scrollLeft,
      });
      console.log(
        `scroll top: ${scrollableElement.scrollTop}, scroll left: ${scrollableElement.scrollLeft}`
      );
    };

    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleAddMetric = () => {
    setIsMenu(false);
    setIsSubmenuVisible(false);
    setManageCustomUnit(false);
    handleChange("openCustom", true);
  };

  const handleSave = async () => {
    const checkEmptyValue =
      customItems?.filter((item) => item.value === "") || [];

    const checkEmptyItemValue = customItems?.flatMap((item) =>
      item.custom_unit_options.filter((option) => option.value === "")
    );

    const emptyItemIds = [
      ...checkEmptyValue.map((item) => item.id),
      ...checkEmptyItemValue.map((option) => option.id),
    ];

    setErrorItems(emptyItemIds);

    if (checkEmptyValue.length > 0 || checkEmptyItemValue.length > 0) return;

    const matrics = customItems.map((item) => {
      return {
        value: item.value,
        id: item.id,
        goal: item.goal,
      };
    });

    const units = customItems.flatMap((item) =>
      item.custom_unit_options.map((option) => {
        if (option.isNew) {
          return {
            value: option.value,
            label: item.id,
          };
        }

        return {
          id: option.id,
          value: option.value,
          label: item.id,
        };
      })
    );

    const formattedData = {
      labels: [...matrics],
      units: [...units],
      unitsToBeDeleted: deletedUnits || [],
      labelsToBeDeleted: deletedItems || [],
    };

    await dispatch(
      blukUpdateGoal(formattedData, planDetails?.id, handleCloseEditModel)
    );
  };

  const handleUpdateUnit = (item) => {
    if (typeof item === "object") {
      setSelectedUnit(item?.value);
    } else {
      setSelectedUnit(item);
    }

    setIsSubmenuVisible(false);
    setIsMenu(false);
    const findUnit = unitsData.find((unit) => unit?.items?.includes(item));
    const value = findUnit?.value;
    typeof item === "object"
      ? handleUpdateGoal("custom_unit", item?.id, "custom")
      : handleUpdateGoal(`${value}_unit`, item, value);
  };

  useEffect(() => {
    setCustomItems(planDetails.goal?.custom_labels);
  }, [planDetails.goal?.custom_labels, manageCustomUnit]);

  return (
    <div>
      <Modal
        footer={null}
        closable={false}
        onCancel={handleCloseEditModel}
        title={false}
        open={manageCustomUnit}
        centered
        width={460}
        className="metricModel"
      >
        <div className="metric-model-header">
          <Row justify="space-between mb-4">
            <h3>Manage Custom Metrics</h3>
            <Cross className={"c-pointer"} onClick={handleCloseEditModel} />
          </Row>
        </div>

        <div className="metric-model-body">
          {customItems && customItems?.length > 0 ? (
            customItems?.map((item, index) => {
              const sortData = [...item?.custom_unit_options].sort(
                (a, b) => a.id - b.id
              );

              const data = {
                ...item,
                custom_unit_options: sortData,
              };

              return (
                <ToggleMenu
                  title={item?.value}
                  customItems={customItems}
                  setCustomItems={setCustomItems}
                  deletedItems={deletedItems}
                  setDeletedItems={setDeletedItems}
                  deletedUnits={deletedUnits}
                  setDeletedUnits={setDeletedUnits}
                  errorItems={errorItems}
                  setErrorItems={setErrorItems}
                  planDetails={data}
                  key={index}
                />
              );
            })
          ) : (
            <div className="empty-metric">No Custom Metrics Added</div>
          )}
        </div>

        <div className="metric-model-footer">
          <div
            className="footer-addMetricBtn addMetricBtn"
            onClick={handleAddMetric}
          >
            <PlusOutlined />
            Add Metric
          </div>

          <div className="footer-actions">
            <button onClick={handleCloseEditModel}>Cancel</button>

            <Button onClick={handleSave} disabled={loading} loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <div
        className="unit-selected"
        onClick={() => setIsMenu(true)}
        onMouseEnter={() => setIsMenu(true)}
        onMouseLeave={() => {
          setIsSubmenuVisible(false);
          setIsMenu(false);
        }}
      >
        <h3>{selectedUnit || getCustomUnitName(planDetails)}</h3>

        <ArrowDown
          style={{
            transform: isSubmenuVisible ? "rotate(180deg)2" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        />
      </div>

      <div
        ref={dropdownRef}
        onMouseEnter={() => setIsMenu(true)}
        onMouseLeave={() => {
          setIsSubmenuVisible(false);
          setIsMenu(false);
        }}
        className={`customDropdown ${
          isMenu ? "customDropdown-open" : "customDropdown-close"
        }`}
      >
        <div className="customDropdown-box">
          <h3 className="customDropdown-title">Default Metrics</h3>
          <ul className="customDropdown-Menus">
            {unitsData?.map((unit) => (
              <li
                key={unit?.value}
                className="customDropdown-Menu"
                onMouseEnter={(e) => handleMouseEnter(e, unit?.items, false)}
                onMouseLeave={handleMouseLeave}
              >
                {unit?.label}
                {unit?.items && <ArrowRight />}
              </li>
            ))}
          </ul>
        </div>
        {customItems && customItems?.length > 0 && (
          <div className="customDropdown-box">
            <h3 className="customDropdown-title">
              Custom Metrics
              <PencilIcon
                className="cursor-pointer"
                onClick={() => {
                  setManageCustomUnit(true);
                  setIsSubmenuVisible(false);
                  setIsMenu(false);
                }}
              />
            </h3>
            <ul className="customDropdown-Menus">
              {customItems?.map((item) => {
                return (
                  <li
                    key={item?.value}
                    className="customDropdown-Menu"
                    onMouseEnter={(e) =>
                      handleMouseEnter(e, item?.custom_unit_options, true)
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    {item?.value}
                    {item?.custom_unit_options?.length > 0 && <ArrowRight />}
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        <div className="addMetricBtn" onClick={handleAddMetric}>
          <PlusOutlined />
          Add Metric
        </div>
      </div>

      {isSubmenuVisible && (
        <div
          ref={submenuRef}
          className="customDropdown-submenu"
          style={{
            top: `${submenuPosition.top}px`,
            left: `${submenuPosition.left}px`,
          }}
          onMouseEnter={() => {
            setIsSubmenuVisible(true);
            setIsMenu(true);
          }}
          onMouseLeave={() => {
            setIsSubmenuVisible(false);
            setIsMenu(false);
          }}
        >
          <ul className="customDropdown-submenu-items">
            {submenuData?.map((item, i) => {
              const isCustomUnit = typeof item === "object";
              return (
                <li
                  className="customDropdown-submenu-item"
                  key={i}
                  onClick={() => handleUpdateUnit(item)}
                >
                  {isCustomUnit ? item?.value : item}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
