import { all, call, put, takeLatest } from "redux-saga/effects";
import { Axios } from "../../api/axios";
import { getSimplifiedError } from "../../utils/error";
import { toast } from "react-toastify";

import {
  CREATE_PLAN_ERROR,
  CREATE_PLAN_REQUEST,
  CREATE_PLAN_SUCCESS,
  GET_PLAN_SUCCESS,
  GET_PLAN_ERROR,
  GET_PLAN_REQUEST,
  GET_PLAN_BY_ID_REQUEST,
  GET_PLAN_BY_ID_SUCCESS,
  GET_PLAN_BY_ID_ERROR,
  REMOVE_PLAN_REQUEST,
  REMOVE_PLAN_SUCCESS,
  REMOVE_PLAN_ERROR,
  CREATE_ACTION_ITEM_REQUEST,
  CREATE_ACTION_ITEM_SUCCESS,
  CREATE_ACTION_ITEM_ERROR,
  UPDATE_ACTION_ITEM_REQUEST,
  UPDATE_ACTION_ITEM_SUCCESS,
  UPDATE_ACTION_ITEM_ERROR,
  DELETE_ACTION_ITEM_REQUEST,
  DELETE_ACTION_ITEM_SUCCESS,
  DELETE_ACTION_ITEM_ERROR,
  CREATE_PLAN_CATEGORY_REQUEST,
  CREATE_PLAN_CATEGORY_SUCCESS,
  CREATE_PLAN_CATEGORY_ERROR,
  UPDATE_PLAN_CATEGORY_REQUEST,
  UPDATE_PLAN_CATEGORY_ERROR,
  UPDATE_PLAN_CATEGORY_SUCCESS,
  DELETE_PLAN_CATEGORY_REQUEST,
  DELETE_PLAN_CATEGORY_ERROR,
  DELETE_PLAN_CATEGORY_SUCCESS,
  CREATE_NOTES_REQUEST,
  UPDATE_NOTES_REQUEST,
  DELETE_NOTES_REQUEST,
  DELETE_NOTES_ERROR,
  DELETE_NOTES_SUCCESS,
  UPDATE_NOTES_ERROR,
  UPDATE_NOTES_SUCCESS,
  CREATE_NOTES_ERROR,
  CREATE_NOTES_SUCCESS,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_ERROR,
  UPDATE_PLAN_REQUEST,
  CREATE_TOOL_REQUEST,
  UPDATE_TOOL_REQUEST,
  DELETE_TOOL_REQUEST,
  DELETE_TOOL_ERROR,
  DELETE_TOOL_SUCCESS,
  UPDATE_TOOL_ERROR,
  UPDATE_TOOL_SUCCESS,
  CREATE_TOOL_ERROR,
  CREATE_TOOL_SUCCESS,
  CREATE_R_FILE_REQUEST,
  UPDATE_R_FILE_REQUEST,
  DELETE_R_FILE_REQUEST,
  DELETE_R_FILE_ERROR,
  DELETE_R_FILE_SUCCESS,
  UPDATE_R_FILE_ERROR,
  UPDATE_R_FILE_SUCCESS,
  CREATE_R_FILE_ERROR,
  CREATE_R_FILE_SUCCESS,
  UPDATE_RESOURCES_SUCCESS,
  UPDATE_RESOURCES_ERROR,
  UPDATE_RESOURCES_REQUEST,
  UPDATE_GOAL_SUCCESS,
  UPDATE_GOAL_ERROR,
  UPDATE_GOAL_REQUEST,
  DELETE_GOAL_REQUEST,
  DELETE_GOAL_SUCCESS,
  DELETE_GOAL_ERROR,
  CREATE_REMINDER_REQUEST,
  CREATE_REMINDER_SUCCESS,
  CREATE_REMINDER_ERROR,
  ARCHIVE_PLAN_REQUEST,
  ARCHIVE_PLAN_SUCCESS,
  ARCHIVE_PLAN_ERROR,
  ADJUST_CATEGORY_WEIGHT_REQUEST,
  ADJUST_CATEGORY_WEIGHT_SUCCESS,
  ADJUST_CATEGORY_WEIGHT_ERROR,
  GET_TOOL_SUCCESS,
  GET_TOOL_ERROR,
  GET_TOOL_REQUEST,
  CHANGE_ACTION_ITEM_ORDERS_SUCCESS,
  CHANGE_ACTION_ITEM_ORDERS_ERROR,
  CHANGE_ACTION_ITEM_ORDERS_REQUEST,
  CHANGE_PLAN_CATEGORY_ORDERS_ERROR,
  CHANGE_PLAN_CATEGORY_ORDERS_SUCCESS,
  CHANGE_PLAN_CATEGORY_ORDERS_REQUEST,
  CREATE_LABEL_AND_UNITS_REQUEST,
  CREATE_LABEL_AND_UNITS_SUCCESS,
  CREATE_LABEL_AND_UNITS_ERROR,
  CLONE_PLAN_REQUEST,
  CLONE_PLAN_SUCCESS,
  CLONE_PLAN_ERROR,
  GET_SHARED_PLAN_BY_ID_ERROR,
  GET_SHARED_PLAN_BY_ID_SUCCESS,
  GET_SHARED_PLAN_BY_ID_REQUEST,
  BULK_UPDATE_TOOL_REQUEST,
  BULK_UPDATE_TOOL_SUCCESS,
  BULK_UPDATE_TOOL_ERROR,
  GET_ALL_USER_REQUEST,
  GET_ALL_USER_ERROR,
  GET_ALL_USER_SUCCESS,
  UPDATE_COLLABORATOR_REQUEST,
  UPDATE_COLLABORATOR_ERROR,
  UPDATE_COLLABORATOR_SUCCESS,
  CREATE_REQUEST_ACCESS_SUCCESS,
  CREATE_REQUEST_ACCESS_ERROR,
  CREATE_REQUEST_ACCESS_REQUEST,
  CHANGE_CATEGORY_ORDERS_REQUEST,
  CHANGE_CATEGORY_ORDERS_SUCCESS,
  CHANGE_CATEGORY_ORDERS_ERROR,
  BULK_GOAL_UPDATE_REQUEST,
  BULK_GOAL_UPDATE_SUCCESS,
  BULK_GOAL_UPDATE_ERROR,
  UPADTE_GOAL_LABEL_REQUEST,
  UPADTE_GOAL_LABEL_SUCCESS,
  UPADTE_GOAL_LABEL_ERROR
} from "../reducers/PlanReducer";
import { GET_PROJECT_OWNER_REQUEST } from "modules/reducers/UserReducer";

async function updateResouce(id, payload) {
  return await Axios.put(`/api/v1/project/resource/${id}/`, payload);
}

async function updateGoal(id, payload) {
  return await Axios.put(`/api/v1/project/goal/${id}/`, payload);
}

async function updateBulkUpdateGoal(payload) {
  return await Axios.post(`/api/v1/project/bulk_goal_label_custom_unit/`, payload);
}

async function updateGoalLable(payload , id ){
  return await Axios.patch(`/api/v1/project/goal-custom-label/${id}/`, payload);
}

async function deleteGoal(id) {
  return await Axios.delete(`/api/v1/project/goal/${id}/`);
}

async function createNewPlan(payload) {
  return await Axios.post("/api/v1/project/project_plan/", payload);
}

async function getPlans({ payload }) {
  return await Axios.get(`/api/v1/project/project_plan/${payload}`);
}

async function getPlanByID(id) {
  return await Axios.get(`/api/v1/project/project_plan/${id}/`);
}

async function getSharedPlanByID(id) {
  return await Axios.get(`/api/v1/project/shared_project/${id}/`);
}

async function updatePlan(id, payload) {
  return await Axios.put(`/api/v1/project/project_plan/${id}/`, payload);
}

async function removePlan(id) {
  return await Axios.delete(`/api/v1/project/project_plan/${id}/`);
}

async function archivePlan(payload) {
  return await Axios.post(`/api/v1/project/project_plan/archive/`, payload);
}

async function unarchivePlan(payload) {
  return await Axios.post(`/api/v1/project/project_plan/unarchived/`, payload);
}

async function createActionItem(payload) {
  return await Axios.post(`/api/v1/project/action_item/`, payload);
}

async function updateActionItem(id, payload) {
  return await Axios.patch(`/api/v1/project/action_item/${id}/`, payload);
}

async function deleteActionItem(id) {
  return await Axios.delete(`/api/v1/project/action_item/${id}/`, {});
}

async function changeActionItemsOrder(payload) {
  return await Axios.patch(`/api/v1/project/action_item/order/`, payload);
}

async function changeCategoryItemsOrder(payload) {
  return await Axios.patch(`/api/v1/project/action_category/order/`, payload);
}

async function changeCategoryOrder(payload) {
  return await Axios.patch(`/api/v1/project/action_item/change_parent/`, payload);
}

async function createPlanCategory(payload) {
  return await Axios.post(`/api/v1/project/action_category/`, payload);
}

async function updatePlanCategory(id, payload) {
  return await Axios.put(`/api/v1/project/action_category/${id}/`, payload);
}

async function adjustCategoryWeight(payload) {
  return await Axios.patch(
    `/api/v1/project/action_category/adjust_weight/`,
    payload
  );
}

async function deletePlanCategory(id) {
  return await Axios.delete(`/api/v1/project/action_category/${id}/`, {});
}

async function createNotes(payload) {
  return await Axios.post(`/api/v1/project/note/`, payload);
}

async function updateNotes(id, payload) {
  return await Axios.put(`/api/v1/project/note/${id}/`, payload);
}

async function deleteNotes(id) {
  return await Axios.delete(`/api/v1/project/note/${id}/`, {});
}

async function createTool(payload) {
  return await Axios.post(`/api/v1/project/tool/`, payload);
}

async function updateBulkUpdateTool(payload) {
  return await Axios.patch(`/api/v1/project/bulk_update_tools/`, payload);
}

async function updateTool(id, payload) {
  return await Axios.put(`/api/v1/project/tool/${id}/`, payload);
}

async function deleteTool(id) {
  return await Axios.delete(`/api/v1/project/tool/${id}/`, {});
}

async function createRFile(payload) {
  return await Axios.post(`/api/v1/project/file/`, payload);
}

async function updateRFile(id, payload) {
  return await Axios.put(`/api/v1/project/file/${id}/`, payload);
}

async function deleteRFile(id) {
  return await Axios.delete(`/api/v1/project/file/${id}/`, {});
}

async function createReminder(payload) {
  return await Axios.post(`/api/v1/project/reminder-setting/`, payload);
}

async function createCustomLabel(payload) {
  return await Axios.post(`/api/v1/project/goal-custom-label/`, payload);
}

async function createCustomUnit(payload) {
  return await Axios.post(`/api/v1/project/goal-label-custom-unit/`, payload);
}

async function getTools() {
  return await Axios.get(`/api/v1/project/tool/`);
}

async function getAllUsers(project_id) {
  return await Axios.get(
    `/api/v1/project/request_access/?status=1&project_plan=${project_id}`
  );
}

async function updateCollaborator(payload, id) {
  return await Axios.patch(`/api/v1/project/request_access/${id}/`, payload);
}

async function createRequestAccess(payload) {
  return await Axios.post(`/api/v1/project/request_access/`, payload);
}

async function clonePlan(payload) {
  return await Axios.post(`/api/v1/project/project_plan/clone_project/`, payload);
}

function* handleCreatePlan({ payload, closeModal, resetState }) {
  try {
    const { data: response } = yield call(createNewPlan, payload);
    if (response) {
      // yield call(createResouce, { project_plan: response?.id })
      yield put({
        type: CREATE_PLAN_SUCCESS,
        payload: response,
      });

      closeModal();
      resetState && resetState();
      // toast('Added New Project Plan', { type: 'success' })
    }
  } catch (error) {
    yield put({
      type: CREATE_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          if (
            error.response.data[key][0]?.includes(
              "Ensure this field has no more than 500 characters."
            )
          ) {
            toast(
              "Please ensure that the 'Notes' field does not contain more than 500 characters.",
              {
                type: "error",
              }
            );
          } else {
            toast(error.response.data[key][0], { type: "error" });
          }
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleGetPlans({ payload }) {
  try {
    const { data: response } = yield call(getPlans, payload);
    if (response) {
      yield put({
        type: GET_PLAN_SUCCESS,
        payload: { response, spread: payload?.spread, search: payload?.search },
      });
    }
  } catch (error) {
    yield put({
      type: GET_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleGetPlanByID({ payload, callback }) {
  try {
    const { data: response } = yield call(getPlanByID, payload);
    if (response) {
      yield put({
        type: GET_PLAN_BY_ID_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_PLAN_BY_ID_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.status === 400) {
      if (error?.response?.data?.error) {
        toast(error?.response?.data?.error, { type: "error" });
        if (callback) callback(404);
      }
    }

    if (error?.response?.status === 404) {
      toast("Project plan doest not exist", { type: "error" });

      if (callback) callback(404);
    } else if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleGetSharedPlanByID({ payload, callback }) {
  try {
    const { data: response } = yield call(getSharedPlanByID, payload);
    if (response) {
      yield put({
        type: GET_SHARED_PLAN_BY_ID_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_SHARED_PLAN_BY_ID_ERROR,
      error: "",
    });

    if (error?.response?.status === 400) {
      if (error?.response?.data?.error) {
        if (callback) callback(404);
      }
    }
    if (error?.response?.status === 404) {
      toast("Project plan doest not exist", { type: "error" });

      if (callback) callback(404);
    } else if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleUpdatePlan({ id, payload, closeModal, noAlert }) {
  try {
    const { data: res } = yield call(updatePlan, id, payload);
    yield put({
      type: UPDATE_PLAN_SUCCESS,
      payload: res,
    });
    yield put({
      type: GET_PLAN_BY_ID_SUCCESS,
      payload: res,
    });
    closeModal && closeModal();
    // !noAlert && toast('Plan has been updated', { type: 'success' })
  } catch (error) {
    yield put({
      type: UPDATE_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          if (
            error.response.data[key][0]?.includes(
              "Ensure this field has no more than 255 characters."
            )
          ) {
            toast("Ensure to enter not more than 255 characters", {
              type: "error",
            });
          } else {
            toast(error.response.data[key][0], { type: "error" });
          }
        }
      }
    } else {
      if (
        getSimplifiedError(error).includes(
          "Ensure this field has no more than 255 characters."
        )
      ) {
        toast("Ensure to enter not more than 255 characters", {
          type: "error",
        });
      } else {
        toast(getSimplifiedError(error), { type: "error" });
      }
    }
  }
}

function* handleRemovePlan({ payload, goBack }) {
  try {
    yield call(removePlan, payload);
    yield put({
      type: REMOVE_PLAN_SUCCESS,
      payload,
    });

    goBack();
    // toast('Plan has been removed', { type: 'success' })
  } catch (error) {
    console.error(error);

    yield put({
      type: REMOVE_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleArchivePlan({ payload, goBack, closeModal }) {
  try {
    yield call(payload?.is_archived ? archivePlan : unarchivePlan, payload);
    yield put({
      type: ARCHIVE_PLAN_SUCCESS,
      payload,
    });

    goBack && goBack();
    closeModal && closeModal();
    // toast('Plan has been archived', { type: 'success' })
  } catch (error) {
    yield put({
      type: ARCHIVE_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleCreateActionItem({ payload, goBack }) {
  try {
    const { data: response } = yield call(createActionItem, payload);
    const payloadNote = {
      action_item: response?.id,
      // project_plan: payload?.project_plan,
      context: "",
    };
    yield put({
      type: CREATE_NOTES_REQUEST,
      payload: payloadNote,
      project_plan: payload?.project_plan,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: payload?.project_plan,
    });
    yield put({
      type: CREATE_ACTION_ITEM_SUCCESS,
    });
    goBack && goBack();
    // toast('Action item has been created', { type: 'success' })
  } catch (error) {
    yield put({
      type: CREATE_ACTION_ITEM_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleUpdateActionItem({ id, payload, project_plan, noAlert }) {
  try {
    yield call(updateActionItem, id, payload);
    yield put({
      type: UPDATE_ACTION_ITEM_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    // !noAlert && toast('Action item has been updated', { type: 'success' })
  } catch (error) {
    yield put({
      type: UPDATE_ACTION_ITEM_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleDeleteActionItem({ payload, project_plan, closeModal }) {
  try {
    yield call(deleteActionItem, payload);
    yield put({
      type: DELETE_ACTION_ITEM_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    closeModal && closeModal();
    // toast('Action item has been removed', { type: 'success' })
  } catch (error) {
    yield put({
      type: DELETE_ACTION_ITEM_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleChangeActionItemsOrder({ payload, project_plan }) {
  try {
    /* yield put({
      type: CHANGE_ACTION_ITEM_ORDERS_SUCCESS,
      payload
    }); */

    yield call(changeActionItemsOrder, payload);

    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
  } catch (error) {
    yield put({
      type: CHANGE_ACTION_ITEM_ORDERS_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleChangeCategoryItemsOrder({ payload, project_plan }) {
  try {
    yield call(changeCategoryItemsOrder, payload);
    yield put({
      type: CHANGE_PLAN_CATEGORY_ORDERS_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
  } catch (error) {
    yield put({
      type: CHANGE_PLAN_CATEGORY_ORDERS_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleChangeCategoryOrder({ payload, project_plan }) {
  try {
    yield call(changeCategoryOrder, payload);
    yield put({
      type: CHANGE_CATEGORY_ORDERS_SUCCESS,
    });
    // yield put({
    //   type: GET_PLAN_BY_ID_REQUEST,
    //   payload: project_plan,
    // });
  } catch (error) {
    yield put({
      type: CHANGE_CATEGORY_ORDERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleCreatePlanCategory({ payload, removeNewList }) {
  try {
    yield call(createPlanCategory, payload);
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: payload?.project_plan,
    });
    yield put({
      type: CREATE_PLAN_CATEGORY_SUCCESS,
    });
    removeNewList && removeNewList();
    // toast('Category has been created', { type: 'success' })
  } catch (error) {
    yield put({
      type: CREATE_PLAN_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleUpdatePlanCategory({ id, payload }) {
  try {
    const { data } = yield call(updatePlanCategory, id, payload);

    yield put({
      type: UPDATE_PLAN_CATEGORY_SUCCESS,
    });

    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: data?.project_plan,
    });
  } catch (error) {
    yield put({
      type: UPDATE_PLAN_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleAdjustCategoryWeight({ payload, project_plan, closeModal }) {
  try {
    yield call(adjustCategoryWeight, payload);
    yield put({
      type: ADJUST_CATEGORY_WEIGHT_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    closeModal && closeModal();
    // toast('Category weight has been updated', { type: 'success' })
  } catch (error) {
    yield put({
      type: ADJUST_CATEGORY_WEIGHT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleDeletePlanCategory({ payload, project_plan, closeModal }) {
  try {
    yield call(deletePlanCategory, payload);
    yield put({
      type: DELETE_PLAN_CATEGORY_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    closeModal && closeModal();
    // toast('Category has been removed', { type: 'success' })
  } catch (error) {
    yield put({
      type: DELETE_PLAN_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleCreateNotes({ payload, project_plan, goBack }) {
  try {
    yield call(createNotes, payload);
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    yield put({
      type: CREATE_NOTES_SUCCESS,
    });
    if (goBack) {
      goBack();
    }
    // toast('Note has been created', { type: 'success' })
  } catch (error) {
    yield put({
      type: CREATE_NOTES_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleUpdateNotes({ id, payload, project_plan, callback }) {
  try {
    yield call(updateNotes, id, payload);
    yield put({
      type: UPDATE_NOTES_SUCCESS,
    });

    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });

    callback && callback();
    // toast('Note has been updated', { type: 'success' })
  } catch (error) {
    yield put({
      type: UPDATE_NOTES_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleDeleteNotes({ payload, project_plan, closeModal }) {
  try {
    yield call(deleteNotes, payload);
    yield put({
      type: DELETE_NOTES_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    closeModal && closeModal();
    // toast('Note has been removed', { type: 'success' })
  } catch (error) {
    yield put({
      type: DELETE_NOTES_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleCreateTool({ payload, closeModal }) {
  try {
    const { data: response } = yield call(createTool, payload);
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: payload?.project_plan,
    });
    if (response) {
      yield put({
        type: CREATE_TOOL_SUCCESS,
        payload: response,
      });
    }
    yield put({
      type: GET_TOOL_REQUEST,
    });
    closeModal();
    // toast('Tool has been created', { type: 'success' })
  } catch (error) {
    yield put({
      type: CREATE_TOOL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleBulkUpdateTool({ payload, id, closeModal }) {
  try {
    const { data: response } = yield call(updateBulkUpdateTool, payload);
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: id,
    });
    if (response) {
      yield put({
        type: BULK_UPDATE_TOOL_SUCCESS,
        payload: response,
      });
    }
    yield put({
      type: GET_TOOL_REQUEST,
    });
    closeModal();
    // toast('Tool has been created', { type: 'success' })
  } catch (error) {
    yield put({
      type: BULK_UPDATE_TOOL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleUpdateTool({ id, payload }) {
  try {
    yield call(updateTool, id, payload);
    yield put({
      type: UPDATE_TOOL_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: payload?.project_plan,
    });
    // toast('Note has been updated', { type: 'success' })
  } catch (error) {
    yield put({
      type: UPDATE_TOOL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleDeleteTool({ payload, project_plan }) {
  try {
    yield call(deleteTool, payload);
    yield put({
      type: DELETE_TOOL_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    // toast('Tool has been removed', { type: 'success' })
  } catch (error) {
    yield put({
      type: DELETE_TOOL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleCreateRFile({ payload, project_plan }) {
  try {
    yield call(createRFile, payload);
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    yield put({
      type: CREATE_R_FILE_SUCCESS,
    });
    // toast('File has been uploaded', { type: 'success' })
  } catch (error) {
    yield put({
      type: CREATE_R_FILE_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleUpdateRFile({ id, payload }) {
  try {
    yield call(updateRFile, id, payload);
    yield put({
      type: UPDATE_R_FILE_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: payload?.project_plan,
    });
    // toast('File has been updated', { type: 'success' })
  } catch (error) {
    yield put({
      type: UPDATE_R_FILE_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleDeleteRFile({ payload, project_plan }) {
  try {
    yield call(deleteRFile, payload);
    yield put({
      type: DELETE_R_FILE_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    // toast('File has been removed', { type: 'success' })
  } catch (error) {
    yield put({
      type: DELETE_R_FILE_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleUpdateResources({
  id,
  payload,
  project_plan,
  closeModal,
  message,
}) {
  try {
    yield call(updateResouce, id, payload);
    yield put({
      type: UPDATE_RESOURCES_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    const qs = ``;
    yield put({
      type: GET_PROJECT_OWNER_REQUEST,
      payload: qs,
      plan: true,
    });
    closeModal();
    // toast(message, { type: 'success' })
  } catch (error) {
    yield put({
      type: UPDATE_RESOURCES_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.status === 400) {
      if (error?.response?.data) {
        toast(error?.response?.data?.message, { type: "error" });
      }
    }
    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleUpdateGoal({ id, payload, project_plan, key }) {
  try {
    yield call(updateGoal, id, payload);
    yield put({
      type: UPDATE_GOAL_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    const noAlert =
      key === "start" || key === "current" || key === "target" ? true : false;
    const message =
      key === "name"
        ? "Objective"
        : key?.includes("unit")
        ? "Unit"
        : key?.charAt(0).toUpperCase() + key.slice(1);
    // !noAlert &&
    // toast(`${message || 'Goal'} has been updated`, { type: 'success' })
  } catch (error) {
    yield put({
      type: UPDATE_GOAL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleBulkUpdateGoal({ payload, project_plan , handleClose }) {
  try {
    yield call(updateBulkUpdateGoal, payload);
    yield put({
      type: BULK_GOAL_UPDATE_SUCCESS,
    });
    toast.success("Metrics have been updated successfully.", { type: "success" });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    handleClose && handleClose();
  } catch (error) {
    yield put({
      type: BULK_GOAL_UPDATE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleUpdateGoalLable({ payload }) {
  try {
    for (let i = 0; i < payload?.length; i++) {
      const element = payload[i];
      const payloadLabel = {
        value: element?.newValue,
      };
      yield call(updateGoalLable, payloadLabel , element?.id);
    }
    yield put({
      type: UPADTE_GOAL_LABEL_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: UPADTE_GOAL_LABEL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleDeleteGoal({ id, project_plan }) {
  try {
    yield call(deleteGoal, id);
    yield put({
      type: DELETE_GOAL_SUCCESS,
    });
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    // toast('File has been removed', { type: 'success' })
  } catch (error) {
    yield put({
      type: DELETE_GOAL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleCreateReminder({ payload, project_plan, closeModal }) {
  try {
    yield call(createReminder, payload);
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    yield put({
      type: CREATE_REMINDER_SUCCESS,
    });
    closeModal && closeModal();
    // toast('Reminder has been added', { type: 'success' })
  } catch (error) {
    yield put({
      type: CREATE_REMINDER_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleCreateCustomLabelAndUnits({
  payload,
  selectedUnitValue,
  project_plan,
  closeModal,
}) {
  try {
    const { data: res } = yield call(createCustomLabel, payload);
    for (let i = 0; i < selectedUnitValue.length; i++) {
      const element = selectedUnitValue[i];
      const payloadLabel = {
        name: element,
        value: element,
        label: res?.id,
      };
      yield call(createCustomUnit, payloadLabel);
    }
    yield put({
      type: GET_PLAN_BY_ID_REQUEST,
      payload: project_plan,
    });
    yield put({
      type: CREATE_LABEL_AND_UNITS_SUCCESS,
    });
    closeModal && closeModal();
    // toast('Reminder has been added', { type: 'success' })
  } catch (error) {
    yield put({
      type: CREATE_LABEL_AND_UNITS_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleGetTools({ payload }) {
  try {
    const { data: response } = yield call(getTools, payload);
    if (response) {
      yield put({
        type: GET_TOOL_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_TOOL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

function* handleGetAllUsers({ payload }) {
  try {
    const { data: response } = yield call(getAllUsers, payload);
    if (response) {
      yield put({
        type: GET_ALL_USER_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_ALL_USER_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      for (const key in error?.response?.data) {
        if (Array.isArray(error?.response?.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    }
  }
}

function* handleUpdateCollaborator({ payload, id }) {
  try {
    yield call(updateCollaborator, payload, id);
    yield put({
      type: UPDATE_COLLABORATOR_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: UPDATE_COLLABORATOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleCreateCollaborator({ payload, navigate }) {
  try {
    yield call(createRequestAccess, payload);
    yield put({
      type: CREATE_REQUEST_ACCESS_SUCCESS,
    });
    navigate && navigate("/project-planner/access-requested");
    // toast.success("Request has been sent successfully.", { type: "success" });
  } catch (error) {
    yield put({
      type: CREATE_REQUEST_ACCESS_ERROR,
      error: getSimplifiedError(error),
    });

    if (error?.response?.data) {
      if (error?.response?.data?.message) {
        toast(error?.response?.data?.message, { type: "error" });
      } else {
        for (const key in error?.response?.data) {
          if (Array.isArray(error?.response?.data[key])) {
            toast(error.response.data[key][0], { type: "error" });
          }
        }
      }
    }
  }
}

function* handleClonePlan({ payload, callback }) {
  try {
    const { data: response } = yield call(clonePlan, payload);

    if (response) {
      yield put({
        type: CLONE_PLAN_SUCCESS,
      });

      toast("Copy created successfully.", { type: "success" });

      if (callback) callback(response);
    }
  } catch (error) {
    yield put({
      type: CLONE_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    toast(getSimplifiedError(error), { type: "error" });

    if (callback) callback(null);
  }
}

export default all([
  takeLatest(CREATE_PLAN_REQUEST, handleCreatePlan),
  takeLatest(GET_PLAN_REQUEST, handleGetPlans),
  takeLatest(GET_PLAN_BY_ID_REQUEST, handleGetPlanByID),
  takeLatest(UPDATE_PLAN_REQUEST, handleUpdatePlan),
  takeLatest(REMOVE_PLAN_REQUEST, handleRemovePlan),
  takeLatest(ARCHIVE_PLAN_REQUEST, handleArchivePlan),
  takeLatest(CREATE_ACTION_ITEM_REQUEST, handleCreateActionItem),
  takeLatest(UPDATE_ACTION_ITEM_REQUEST, handleUpdateActionItem),
  takeLatest(DELETE_ACTION_ITEM_REQUEST, handleDeleteActionItem),
  takeLatest(CHANGE_ACTION_ITEM_ORDERS_REQUEST, handleChangeActionItemsOrder),
  takeLatest(
    CHANGE_PLAN_CATEGORY_ORDERS_REQUEST,
    handleChangeCategoryItemsOrder
  ),
  takeLatest(CHANGE_CATEGORY_ORDERS_REQUEST , handleChangeCategoryOrder),
  takeLatest(CREATE_PLAN_CATEGORY_REQUEST, handleCreatePlanCategory),
  takeLatest(UPDATE_PLAN_CATEGORY_REQUEST, handleUpdatePlanCategory),
  takeLatest(ADJUST_CATEGORY_WEIGHT_REQUEST, handleAdjustCategoryWeight),
  takeLatest(DELETE_PLAN_CATEGORY_REQUEST, handleDeletePlanCategory),
  takeLatest(CREATE_NOTES_REQUEST, handleCreateNotes),
  takeLatest(UPDATE_NOTES_REQUEST, handleUpdateNotes),
  takeLatest(DELETE_NOTES_REQUEST, handleDeleteNotes),
  takeLatest(CREATE_TOOL_REQUEST, handleCreateTool),
  takeLatest(BULK_UPDATE_TOOL_REQUEST, handleBulkUpdateTool),
  takeLatest(UPDATE_TOOL_REQUEST, handleUpdateTool),
  takeLatest(DELETE_TOOL_REQUEST, handleDeleteTool),
  takeLatest(CREATE_R_FILE_REQUEST, handleCreateRFile),
  takeLatest(UPDATE_R_FILE_REQUEST, handleUpdateRFile),
  takeLatest(DELETE_R_FILE_REQUEST, handleDeleteRFile),
  takeLatest(UPDATE_RESOURCES_REQUEST, handleUpdateResources),
  takeLatest(UPDATE_GOAL_REQUEST, handleUpdateGoal),
  takeLatest(BULK_GOAL_UPDATE_REQUEST, handleBulkUpdateGoal),
  takeLatest(UPADTE_GOAL_LABEL_REQUEST , handleUpdateGoalLable),
  takeLatest(DELETE_GOAL_REQUEST, handleDeleteGoal),
  takeLatest(CREATE_REMINDER_REQUEST, handleCreateReminder),
  takeLatest(CREATE_LABEL_AND_UNITS_REQUEST, handleCreateCustomLabelAndUnits),
  takeLatest(GET_TOOL_REQUEST, handleGetTools),
  takeLatest(GET_ALL_USER_REQUEST, handleGetAllUsers),
  takeLatest(UPDATE_COLLABORATOR_REQUEST, handleUpdateCollaborator),
  takeLatest(CREATE_REQUEST_ACCESS_REQUEST, handleCreateCollaborator),
  takeLatest(CLONE_PLAN_REQUEST, handleClonePlan),
  takeLatest(GET_SHARED_PLAN_BY_ID_REQUEST, handleGetSharedPlanByID)
]);
