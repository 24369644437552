import { all, call, put, takeLatest } from "redux-saga/effects";
import Cookies from "js-cookie";

import { Axios } from "api/axios";
import { UserService } from "api/userService";

import axios from "axios";
import { apiBaseURL as baseURL } from "../../utils/constants";
import { getSimplifiedError } from "../../utils/error";
import { toast } from "react-toastify";
import { setLocalItem } from "../../utils/StorageVariables"

import {
  CHECK_AUTH_REQUEST,
  CHECK_AUTH_SUCCESS,
  CHECK_AUTH_ERROR,

  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,

  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,

  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,

  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_ERROR,

  FACEBOOK_LOGIN_REQUEST,
  FACEBOOK_LOGIN_SUCCESS,
  FACEBOOK_LOGIN_ERROR,

  APPLE_LOGIN_REQUEST,
  APPLE_LOGIN_SUCCESS,
  APPLE_LOGIN_ERROR,

  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,

  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,

  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,

  GET_USER_FROM_LOCAL_STORAGE_REQUEST,
  GET_USER_FROM_LOCAL_STORAGE_SUCCESS,
  GET_USER_FROM_LOCAL_STORAGE_ERROR,

  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_REQUEST,

  CHANGE_USER_PROFILE_SUCCESS,
  CHANGE_USER_PROFILE_ERROR,
  CHANGE_USER_PROFILE_REQUEST,

  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_REQUEST,

  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,

  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,

  SET_MODAL,
} from "../reducers/AuthReducer";

import { GET_PROJECT_NOTIFICATIONS_REQUEST } from "modules/reducers/UserReducer";
import { getAppBaseURL, getToken } from "utils/utility";
import { ALLOCATOR_TOOL } from "../../utils/routes";

async function getCheckAuth() {
  return UserService.get(`/user/check_auth/`);
}

async function getCheckAuthLocal() {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rawData = Cookies.get('token-local');

      if (!rawData || typeof rawData === "undefined") {
        resolve({
          data: {
            authenticated: false
          }
        });

        return;
      }

      const data = JSON.parse(rawData);

      resolve({
        data: {
          authenticated: true,
          ...data
        }
      });
    }, 150); // Simulate response time
  });
}

function* handleCheckAuth() {
  try {
    const { data: results } = process.env.NODE_ENV === 'development'
      ? yield call(getCheckAuthLocal)
      : yield call(getCheckAuth);

    if (results) {
      const data = results.token && results.user
        ? results
        : { token: '', user: {} }

      yield put({
        type: CHECK_AUTH_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.error('🔴', error);

    // Due to CORS error 🤷‍♀️
    if (process.env.NODE_ENV === 'development') {
      yield put({
        type: CHECK_AUTH_SUCCESS,
        data: { user: {} }
      });
    }
    else {
      yield put({
        type: CHECK_AUTH_ERROR,
        error: getSimplifiedError(error),
      });
    }
  }
}

async function signup(form) {
  const url = getAppBaseURL();

  if (!url)
    return false;

  const dataForm = {
    ...form,
    redirect_url: `https://${url}/`
  }
  return await Axios.post("/api/v1/decision_matrix/users/", dataForm);
}

/* async function signup(form) {
  const config = {};

  // Test
  config.withCredentials = true;

  return Axios.post(`/api/v1/decision_matrix/users/`, form, config);
} */

async function getUserProfileAPI(token) {
  /* const config = {};
  config.headers = { Authorization: `Token ${token}` };

  return Axios.get(`/api/v1/profile/get/`, config); */

  return UserService.get(`/user/${id}/`);
}

function* handlegetUserProfile({ token }) {
  try {
    const { data: results } = yield call(getUserProfileAPI, token);
    if (results) {
      localStorage.setItem("user", JSON.stringify(results?.user));
      yield put({
        type: GET_USER_PROFILE_SUCCESS,
        data: results?.user,
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_PROFILE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function changeUserProfileAPI({ form, id }) {
  return UserService.patch(`user/${id}/`, form);
}

function* handleChangeUserProfile({ payload }) {
  try {
    const { data: results } = yield call(changeUserProfileAPI, payload);

    if (results) {
      localStorage.setItem("user", JSON.stringify(results));

      // TODO
      /* if (process.env.NODE_ENV === 'development')
        Cookies.set('token-local'); */

      yield put({
        type: CHANGE_USER_PROFILE_SUCCESS,
        data: results,
      });

      toast("Saved successfully", { type: "success" });
    }
  } catch (error) {
    console.error('🔴', error);

    yield put({
      type: CHANGE_USER_PROFILE_ERROR,
      error: getSimplifiedError(error),
    });

    toast(getSimplifiedError(error).toString(), { type: "error" });
  }
}

async function updatePassword(payload) {
  return UserService.patch(`/user/pp_password_change/`, payload);
}

function* handleUpdatePassword({ payload, callback }) {
  try {
    const { data: results } = yield call(updatePassword, payload);

    if (results) {
      yield put({
        type: UPDATE_PASSWORD_SUCCESS,
        data: results,
      });

      if (callback)
        callback();

      toast(results.message, { type: "success" });
    }
  } catch (error) {
    yield put({
      type: UPDATE_PASSWORD_ERROR,
      error: getSimplifiedError(error),
    });

    toast(getSimplifiedError(error).toString(), { type: "error" });
  }
}

function* handleSignup({ payload, navigate }) {
  try {

    const { data: response } = yield call(signup, payload);
    if (response) {
      yield put({
        type: SIGNUP_SUCCESS,
      });
      setLocalItem("userDetail", JSON.stringify(response?.user))
      toast("Signup successfully", { type: "success" });
      navigate();
    }
  } catch (error) {
    yield put({
      type: SIGNUP_ERROR,
      error: getSimplifiedError(error),
    });

    if (error && error.response && error.response.data) {
      for (const key in error.response.data) {
        if (Array.isArray(error.response.data[key])) {
          toast(error.response.data[key][0], { type: "error" });
        }
      }
    } else {
      toast(getSimplifiedError(error), { type: "error" });
    }
  }
}

// 🔴 TODO: FIX!
async function login(data) {
  const url = getAppBaseURL();

  if (!url)
    return false;

  const dataForm = {
    ...data,
    url: `https://${url}/reset-password?token=`
  }

  return await UserService.post("/user/login/", dataForm);
}

function* handleLogin({ payload, navigate, token, redirect }) {
  try {
    /* if (!token) {
      const { data: response } = yield call(login, payload);
      token = response.token;
    } */

    const { data: response } = yield call(login, payload);

    if (response) {
      if (!response.user.is_verified) {
        setLocalItem("userDetail", JSON.stringify(response?.user))
        navigate(`/otp?app=${redirect}`);
        return
      }

      yield put({
        type: GET_USER_PROFILE_SUCCESS,
        data: response.user,
      });

      yield put({
        type: LOGIN_SUCCESS,
        data: {
          user: response.user,
          token: response.token
        }
      });

      yield put({
        type: GET_PROJECT_NOTIFICATIONS_REQUEST,
      });

      if (process.env.NODE_ENV === 'development')
        Cookies.set('token-local', JSON.stringify(response));

      const dmToClone = Cookies.get("dm-to-clone");
      const ppToClone = Cookies.get("pp-to-clone");

      if (dmToClone) {
        navigate(`/shared-decision/${dmToClone}?clone=true`, { replace: true });
        Cookies.remove("dm-to-clone");

        return;
      }

      if (ppToClone) {
        navigate(`/shared-project/${ppToClone}?clone=true`, { replace: true });
        Cookies.remove("pp-to-clone");

        return;
      }

      if (response.user?.app_access && response.user?.app_access?.allocator_tool && (redirect === "dm" || redirect === "at")) {
        navigate(`/${ALLOCATOR_TOOL}`, { replace: true });
      }
      else {
        navigate(
          redirect && redirect === "pp"
            ? "/project-planner"
            : "/decision-manager",
          { replace: true }
        );
      }
    }
  } catch (error) {
    console.error(error)

    yield put({
      type: LOGIN_ERROR,
      error: getSimplifiedError(error),
    });

    toast(getSimplifiedError(error).toString(), { type: "error" });
  }
}

async function googleLogin(payload) {
  return await Axios.post("/login/google/", payload);
}
function* handleGoogleLogin({ payload }) {
  try {
    const { data: response } = yield call(googleLogin, payload);
    if (response.key) {
      Cookies.set("lmos-token", response.key, 14, { domain: 'arootah.com' });
      yield put({
        type: GOOGLE_LOGIN_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: GOOGLE_LOGIN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function facebookLogin(payload) {
  return await Axios.post("/login/facebook/", payload);
}
function* handleFacebookLogin({ payload }) {
  try {
    const { data: response } = yield call(facebookLogin, payload);
    if (response.key) {
      Cookies.set("lmos-token", response.key, 14, { domain: 'arootah.com' });
      yield put({
        type: FACEBOOK_LOGIN_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: FACEBOOK_LOGIN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function appleLogin(payload) {
  return await Axios.post("/login/apple/", payload);
}
function* handleAppleLogin({ payload }) {
  try {
    const { data: response } = yield call(appleLogin, payload);
    if (response.key) {
      Cookies.set("lmos-token", response.key, 14, { domain: 'arootah.com' });
      yield put({
        type: APPLE_LOGIN_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: APPLE_LOGIN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function forgotPassword(payload) {
  return await UserService.post("/user/send_reset_password_email/", payload);
}
function* handleForgotPassword({ payload, onNavigate }) {
  try {
    const { data: response } = yield call(forgotPassword, payload);
    if (response) {
      yield put({
        type: FORGOT_PASSWORD_SUCCESS,
      });

      onNavigate && onNavigate();
    }
  } catch (error) {
    toast(getSimplifiedError(error).toString(), { type: "error" });

    yield put({
      type: FORGOT_PASSWORD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function changePassword(payload) {
  const url = baseURL.split("/api/v1/")[0];
  const token = Cookies.get("lmos-token");
  return axios.post(`${url}/rest-auth/password/change/`, payload, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}
function* handleChangePassword({ payload }) {
  try {
    const { status } = yield call(changePassword, payload);
    toast("Password changed successfully", { type: "success" });

    if (status === 200) {
      yield put({
        type: CHANGE_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: CHANGE_PASSWORD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function resetPassword(payload, token) {
  return await UserService.post(`/user/reset_password/`, payload, { headers: { token } });
}

function* handleResetPassword({ payload, navigate }) {
  try {
    const { data: response } = yield call(resetPassword, payload.form, payload.token);
    if (response) {
      toast("Password changed successfully", { type: "success" });
      navigate && navigate("/");
      yield put({
        type: RESET_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();
    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );
    yield put({
      type: RESET_PASSWORD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function inviteUser(payload, token) {
  return await UserService.post(`/user/reset_password/`, payload, { headers: { Authorization: `Token ${token}` } });
}
function* handleInviteUser({ payload, navigate }) {
  try {
    const { data: response } = yield call(inviteUser, payload.form, payload.token);
    if (response) {
      toast("Password Created successfully", { type: "success" });
      navigate && navigate("/");
      yield put({
        type: INVITE_USER_SUCCESS,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();
    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );
    yield put({
      type: INVITE_USER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function logout() {
  return await Axios.get(`/api/v1/decision_matrix/users/remove_auth/`);
}
function* handleLogout({ callback }) {
  try {
    const { data: response } = yield call(logout);

    if (response) {
      toast("Logged Out Successfully", { type: "success" });

      if (process.env.NODE_ENV === 'development')
        Cookies.remove('token-local');

      yield put({
        type: LOGOUT_SUCCESS
      });

      if (callback)
        callback();
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: LOGOUT_ERROR,
      error: getSimplifiedError(error),
    });
  } finally {
    Cookies.remove('token-local');
  }
}

function* toggleModal({ payload }) {
  yield put({
    type: SET_MODAL,
    payload,
  });
}
function* handleGetUserFromLocalStorage() {
  try {
    const response = localStorage.getItem("user");
    const token = getToken();

    if (response) {
      yield put({
        type: GET_USER_FROM_LOCAL_STORAGE_SUCCESS,
        data: JSON.parse(response),
      });
    }
    if (token) {
      yield put({
        type: GET_USER_PROFILE_REQUEST,
        token,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();
    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );
    yield put({
      type: LOGOUT_REQUEST,
    });
    yield put({
      type: GET_USER_FROM_LOCAL_STORAGE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteAccount(id) {
 /*  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ status: 204 });
    }, 500);
  }); */

  return UserService.delete(`/user/${id}/`);
}

function* handleDeleteAccount({ id }) {
  try {
    const { status } = yield call(deleteAccount, id);

    if (status === 204) {
      yield put({
        type: DELETE_ACCOUNT_SUCCESS
      });

      yield put({
        type: LOGOUT_SUCCESS
      });

      toast('Account Deleted', { type: "success" });
    }
  } catch (error) {
    console.error(error);

    yield put({
      type: DELETE_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });

    toast(getSimplifiedError(error).toString(), { type: "error" });
  }
}

export default all([
  takeLatest(CHECK_AUTH_REQUEST, handleCheckAuth),
  takeLatest(GET_USER_PROFILE_REQUEST, handlegetUserProfile),
  takeLatest(CHANGE_USER_PROFILE_REQUEST, handleChangeUserProfile),
  takeLatest(UPDATE_PASSWORD_REQUEST, handleUpdatePassword),
  takeLatest(SIGNUP_REQUEST, handleSignup),
  takeLatest(SET_MODAL, toggleModal),
  takeLatest(LOGIN_REQUEST, handleLogin),
  takeLatest(GOOGLE_LOGIN_REQUEST, handleGoogleLogin),
  takeLatest(FACEBOOK_LOGIN_REQUEST, handleFacebookLogin),
  takeLatest(APPLE_LOGIN_REQUEST, handleAppleLogin),
  takeLatest(FORGOT_PASSWORD_REQUEST, handleForgotPassword),
  takeLatest(CHANGE_PASSWORD_REQUEST, handleChangePassword),
  takeLatest(INVITE_USER_REQUEST, handleInviteUser),
  takeLatest(RESET_PASSWORD_REQUEST, handleResetPassword),
  takeLatest(LOGOUT_REQUEST, handleLogout),
  takeLatest(DELETE_ACCOUNT_REQUEST, handleDeleteAccount),
  takeLatest(
    GET_USER_FROM_LOCAL_STORAGE_REQUEST,
    handleGetUserFromLocalStorage
  ),
]);
