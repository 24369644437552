import React, { useMemo } from "react";
import classes from "./compare.module.scss";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { Popover, Spin } from "antd";
import cc from "classnames";
import { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Mitigation } from "scenes/DecisionMatrix";
import useWindowSize from "../../../../utils/useWindowSize";
import moment from "moment";
import { UpdateOptionCriteria } from "redux/DecisionMatrix/UpdateOptionCriteria/action";
import { DeletePros } from "redux/DecisionMatrix/DeletePros/action";
import { DeleteCons } from "redux/DecisionMatrix/DeleteCons/action";
import { UpdatePros } from "redux/DecisionMatrix/UpdatePros/action";
import { UpdateCons } from "redux/DecisionMatrix/UpdateCons/action";
import { generatePath, useLocation, useParams } from "react-router-dom";
import { GetCons } from "redux/DecisionMatrix/GetCons/action";
import { GetPros } from "redux/DecisionMatrix/GetPros/action";
import { ReactComponent as ProsPlusIcon } from "./pros_icon.svg";
import { ReactComponent as ConsPlusIcon } from "./cons_icon.svg";
import { ReactComponent as OptionsIcon } from "./options.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/svg/up-arrow.svg";
import { ReactComponent as DownArrow } from "../../../../assets/svg/down-arrow.svg";
import styled from "styled-components";

const Compare = ({ activeOptionCriteria, readOnly, setAiAssistant, drawerClosed }) => {
  const { decisionId } = useParams();

  const dispatch = useDispatch();
  const [updaterProsCons, setProsCons] = useState([]);
  const [showInput, setShowInput] = useState("");
  const [editProsInput, setEditProsInput] = useState({});
  const [editConsInput, setEditConsInput] = useState({});
  const [pros, setPros] = useState([]);
  const [cons, setCons] = useState([]);
  const [localConsValue, setLocalConsValue] = useState("");
  const [localMitigationValue, setLocalMitigationValue] = useState("");
  const [showCompare, setShowCompare] = useState(true);
  const [isProsMob, setIsProsMob] = useState(true);
  const { width } = useWindowSize();
  const [addMitigation, setAddMitigation] = useState("");
  const prosRef = useRef();
  const consRef = useRef();

  const [prosLimitError, setProsLimitError] = useState(false);
  const [editProsLimitError, setEditProsLimitError] = useState(false);
  const [consLimmitError, setConsLimmitError] = useState(false)
  const [consMigitationLimitError, setConsMigitationLimitError] = useState(false)
  const [editConsLimitError, setEditConsLimitError] = useState(false)
  const [editMitigationLimitError, setEditMitigationLimitError] = useState(false)


  const mitigationRef = useRef();
  const loadingImg = () => {
    return <Spin className={classes.centered} size="large" />;
  };
  const { success: updatedConsRed } = useSelector(
    (state) => state.updatedConsReducer
  );
  const { success: getConsRed } = useSelector((state) => state.getConsReducer);
  const { success: getProsRed } = useSelector((state) => state.getProsReducer);

  useEffect(() => {
    if (!activeOptionCriteria?.id) return;
    dispatch(GetCons(activeOptionCriteria?.id));
    dispatch(GetPros(activeOptionCriteria?.id));
  }, [activeOptionCriteria]);

  useEffect(() => {
    if (getProsRed) {
      setPros([
        ...getProsRed?.sort(
          (i1, i2) =>
            moment(i1?.created_at)?.valueOf() -
            moment(i2?.created_at)?.valueOf()
        ),
      ]);
    }
  }, [getProsRed]);

  useEffect(() => {
    if (getConsRed) {
      setCons([
        ...getConsRed?.sort(
          (i1, i2) =>
            moment(i1?.created_at)?.valueOf() -
            moment(i2?.created_at)?.valueOf()
        ),
      ]);
    }
  }, [getConsRed]);

  useEffect(() => {
    setCons([])
    setPros([])
  }, [drawerClosed])


  const addPros = async () => {
    setLocalConsValue("");
    setLocalMitigationValue("");

    if (updaterProsCons[0] === "pros") {
      await editPros(updaterProsCons[1]);
      setProsCons([]);
      return;
    }

    if (prosRef?.current?.value) {
      if (removeLineBreaksReplaceAll(prosRef?.current?.value).length > 2000) {
        setProsLimitError(true)
      } else {
        setProsLimitError(false)
        setShowInput("");
        const id = activeOptionCriteria?.id;
        const body = {
          option: activeOptionCriteria?.option,
          criteria: activeOptionCriteria?.criteria?.id,
          pros: [{ context: prosRef?.current?.value }],
        };
        dispatch(UpdateOptionCriteria(body, id, true, false));
      }
    }
  };

  const addCons = async () => {
    setLocalConsValue("");
    setLocalMitigationValue("");

    if (updaterProsCons[0] === "cons") {
      await editCons(updaterProsCons[1]);
      setProsCons([]);
      return;
    }

    if (consRef.current.value) {
      if (removeLineBreaksReplaceAll(consRef?.current?.value).length > 2000) {
        setConsLimmitError(true)
      } else {
        setConsLimmitError(false)
      }
      if (removeLineBreaksReplaceAll(mitigationRef.current.value).length > 2000) {
        setConsMigitationLimitError(true)
      } else {
        setConsMigitationLimitError(false)
      }
      if (removeLineBreaksReplaceAll(consRef?.current?.value).length <= 2000 && removeLineBreaksReplaceAll(mitigationRef.current.value).length <= 2000) {
        setShowInput("");
        const id = activeOptionCriteria?.id;
        const body = {
          option: activeOptionCriteria?.option,
          criteria: activeOptionCriteria?.criteria?.id,
          cons: [
            {
              context: consRef?.current?.value,
              mitigation: mitigationRef?.current?.value || "",
            },
          ],
        };

        dispatch(UpdateOptionCriteria(body, id, false, true));
      }
    }
  };

  const removePros = (id) => {
    const criteriaId = activeOptionCriteria?.id;

    dispatch(DeletePros(id, criteriaId, decisionId));
  };

  const removeCons = (id) => {
    const criteriaId = activeOptionCriteria?.id;
    dispatch(DeleteCons(id, criteriaId, decisionId));
  };

  const removeLineBreaksReplaceAll = str => {
    return str.replaceAll('\n', '');
  }

  const editPros = async (prosId) => {
    const id = activeOptionCriteria?.id;

    if (removeLineBreaksReplaceAll(prosRef?.current?.value).length > 2000) {
      setEditProsLimitError(true)
    }
    else {
      setEditProsLimitError(false)
      dispatch(
        UpdatePros(
          {
            context: prosRef?.current?.value,
            id: prosId,
          },
          prosId,
          id,
          setEditProsInput
        )
      );
    }
  };

  // const editCons = async (consId) => {
  //   const id = activeOptionCriteria?.id;
  //   let updatedCons;

  //   if (consRef.current) {
  //     dispatch(
  //       UpdateCons(
  //         {
  //           context: consRef.current.value,
  //           mitigation: mitigationRef?.current?.value || "",
  //         },
  //         consId,
  //         id
  //       )
  //     );

  //     const index = cons.findIndex((c) => c.id === updatedConsRed.id);
  //     const consCopy = [...cons];
  //     consCopy[index] = updatedCons;
  //     setCons([...consCopy]);
  //   } else if (mitigationRef.current) {
  //     dispatch(
  //       UpdateCons(
  //         {
  //           mitigation: mitigationRef.current.value,
  //         },
  //         consId,
  //         id
  //       )
  //     );

  //     const consCopy = [...cons];
  //     const index = cons.findIndex((c) => +c.id === +consId);
  //     consCopy[index] = { ...updatedCons };
  //     setCons([...consCopy]);
  //   }

  //   setAddMitigation("");
  // };
  const editCons = async (consId) => {
    let id = activeOptionCriteria?.id;
    let updatedCons = {};

    if (consRef?.current) {
      if (removeLineBreaksReplaceAll(consRef?.current?.value).length > 2000) {
        setEditConsLimitError(true)
      } else {
        setEditConsLimitError(false)
      }
      updatedCons.context = consRef?.current?.value;
    }

    if (mitigationRef.current) {
      if (removeLineBreaksReplaceAll(mitigationRef?.current?.value).length > 2000) {
        setEditMitigationLimitError(true);
      } else {
        setEditMitigationLimitError(false)
      }
      updatedCons.mitigation = mitigationRef?.current?.value;
    }

    if (editConsLimitError === false && editMitigationLimitError === false) {
      dispatch(UpdateCons(updatedCons, consId, id, setEditConsInput));

      let index = cons.findIndex((c) => c?.id === consId);
      let consCopy = [...cons];
      consCopy[index] = { ...consCopy[index], ...updatedCons };
      setCons([...consCopy]);

      setAddMitigation("");
    }
  };

  const localUpdatePros = (prosValue) => {
    let l = removeLineBreaksReplaceAll(prosValue)
    if (removeLineBreaksReplaceAll(prosValue).length > 2000) {
      setProsLimitError(true)
      setEditProsLimitError(true)
    } else {
      setProsLimitError(false)
      setEditProsLimitError(false)
    }
    if (updaterProsCons?.length) {
      const index = pros?.findIndex((p) => p.id === updaterProsCons[1]);
      const prosCopy = [...pros];
      prosCopy[index].context = prosValue;
      setPros([...prosCopy]);
    }
  };

  const handleClick = (action, flag, prosId, prior) => {
    let priorVal = prior; // Get the value from the backend
    if (priorVal === null) {
      priorVal = 0; // Set the value to 0 if it's null
    }

    let newValue;

    if (action === "increase") {
      newValue = priorVal < 3 ? priorVal + 1 : 3;
    } else if (action === "decrease") {
      newValue = priorVal > 0 ? priorVal - 1 : 0;
    }

    const id = activeOptionCriteria?.id;
    if (flag === "pro") {
      dispatch(
        UpdatePros(
          {
            priority: newValue,
            id: prosId,
          },
          prosId,
          id,
          setEditProsInput
        )
      );
    }
    if (flag === "con") {
      dispatch(
        UpdateCons(
          {
            priority: newValue,
            id: prosId,
          },
          prosId,
          id,
          setEditProsInput
        )
      );
    }
  };

  const localUpdateCons = (consValue) => {
    if (removeLineBreaksReplaceAll(consValue).length > 2000) {
      setConsLimmitError(true)
      setEditConsLimitError(true)
    } else {
      setConsLimmitError(false)
      setEditConsLimitError(false)
    }
    if (updaterProsCons?.length) {
      const index = cons?.findIndex((c) => c.id === updaterProsCons[1]);
      const consCopy = [...cons];
      consCopy[index].context = consValue;
      setCons([...consCopy]);
    }
  };

  const localUpdateMitigation = (consValue) => {
    if (removeLineBreaksReplaceAll(consValue).length > 2000) {
      setConsMigitationLimitError(true)
      setEditMitigationLimitError(true)
    } else {
      setConsMigitationLimitError(false)
      setEditMitigationLimitError(false)
    }
    if (updaterProsCons?.length) {
      const index = cons?.findIndex((c) => c?.id === updaterProsCons[1]);
      const consCopy = [...cons];
      consCopy[index].mitigation = consValue;
      setCons([...consCopy]);
    }
  };

  return (
    <div className={classes.compareWrapper}>
      {/* <div className={classes.compareTitle}>Compare</div> */}
      {/* {getProsLoading || getConsLoading ? (
        <div>
          <AppLoading />
        </div>
      ) : ( */}
      <>
        {width > 767 && (
          <div>
            {showCompare ? (
              <>
                <div className={classes.compareTextDiv}>
                  <div className={classes.compareText}>Compare</div>
                  {!readOnly && (
                    <div
                      onClick={() => setAiAssistant(true)}
                      className={classes.generateAI}
                    >
                      Generate with AI
                    </div>
                  )}
                </div>
                <div className={classes.compareTablet}>
                  <div className={classes.column}>
                    {/* <div
                    className={classes.header}
                    style={{ background: "#62B732" }}
                  >
                    <span>Pros</span>
                  </div> */}
                    <div className={classes.header_pros}>
                      <h5>Pros</h5>
                      <button
                        onClick={() => {
                          setShowInput("pros");
                          setProsLimitError(false);
                          setEditProsLimitError(false)
                        }}
                        disabled={readOnly}
                      >
                        <ProsPlusIcon /> Add Pro
                      </button>
                    </div>
                    {pros?.map((p, index) => {
                      const content = (
                        <div key={p.id} className={classes.item}>
                          <button
                            type="button"
                            onClick={async () => {
                              if (p?.id) {
                                await setEditProsInput({ index, id: p?.id });
                                prosRef.current.value = p?.context;
                              } else{
                                console.error('Pros id is not available');
                              }
                            }}
                          >
                            EDIT
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              if(p?.id){
                                setPros(pros?.filter((pd) => pd?.id !== p?.id));
                                removePros(p?.id);
                              } else {
                                console.error('Pros id is not available');
                              }
                            }}
                          >
                            DELETE
                          </button>
                        </div>
                      );
                      return (
                        <div key={p?.id} className={classes.prosWrapper}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={classes.pros}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div className={classes.details}>
                                  <div>{index + 1}.</div>
                                  {editProsInput?.index === index &&
                                    editProsInput?.id === p?.id ? (
                                    <div>
                                      <textarea
                                        rows="1"
                                        className={classes.editPros}
                                        onBlur={() => {
                                          editPros(p?.id);
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.keyCode === 13) {
                                            editPros(p?.id);
                                          }
                                        }}
                                        onChange={(e) =>
                                          localUpdatePros(e?.target?.value)
                                        }
                                        ref={prosRef}
                                        placeholder={"Enter a pro"}
                                        autoFocus={true}
                                        type="text"
                                        style={{ borderColor: editProsInput?.index === index && editProsInput?.id === p?.id && editProsLimitError ? '#f51e14' : null }}
                                      >
                                      </textarea>
                                      {editProsInput?.index === index && editProsInput?.id === p?.id && editProsLimitError && (<div style={{ color: '#f51e14', fontSize: 12 }}>Pros cannot be greater than 2000 characters.</div>)}
                                    </div>
                                  ) : (
                                    <span className={classes.context}>
                                      {p.context}
                                    </span>
                                  )}
                                </div>
                                <ActionItem>
                                  <Sorting>
                                    <Dot
                                      grey={
                                        p?.priority == 0 || p?.priority === null
                                      }
                                      green={p?.priority == 3}
                                      red={p?.priority == 1}
                                      orange={p?.priority == 2}
                                    ></Dot>
                                    <SmallText>
                                      {((p?.priority == 0 ||
                                        p?.priority === null) &&
                                        "None") ||
                                        (p?.priority == 1 && "Low") ||
                                        (p?.priority == 2 && "Medium") ||
                                        (p?.priority == 3 && "High")}
                                    </SmallText>
                                  </Sorting>
                                  <ButtonWrapper>
                                    <Button
                                      onClick={() =>
                                        handleClick(
                                          "increase",
                                          "pro",
                                          p?.id,
                                          p?.priority == "null"
                                            ? 1
                                            : p?.priority
                                        )
                                      }
                                    >
                                      <ArrowUp />
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleClick(
                                          "decrease",
                                          "pro",
                                          p?.id,
                                          p?.priority == "null"
                                            ? 0
                                            : p?.priority
                                        )
                                      }
                                    >
                                      <DownArrow />
                                    </Button>
                                  </ButtonWrapper>
                                </ActionItem>
                                {p.option_criteria && !readOnly && (
                                  <div className={classes.popover_container}>
                                    <Popover
                                      content={content}
                                      onClick={(e) => {
                                        e?.stopPropagation();
                                      }}
                                      trigger="focus"
                                    >
                                      <EllipsisOutlined rotate={90} />
                                    </Popover>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {showInput !== "cons" && (
                      <div className={classes.buttonAdd}>
                        <button
                          type="button"
                          className={classes.button}
                          onClick={() => {
                            setShowInput("pros");
                            // setPros([...pros, {context: '', id: new Date().getTime()}])
                          }}
                          disabled={readOnly}
                        >
                          <PlusOutlined style={{ color: "#62B732" }} />
                          <span style={{ color: "#62B732" }}>
                            {updaterProsCons[0] === "pros" ? "Edit" : "Add"}
                          </span>
                        </button>
                      </div>
                    )}
                    <div style={{ padding: '15px 16px 10px', width: '100%' }}>
                      {showInput === "pros" && (
                        <>
                          <textarea
                            rows="1"
                            className={classes.inputPro}
                            onBlur={() => {
                              addPros();
                              // setShowInput('')
                            }}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                addPros();
                                // setShowInput('')
                              }
                            }}
                            onChange={(e) => localUpdatePros(e?.target?.value)}
                            ref={prosRef}
                            placeholder={"Enter a pro"}
                            autoFocus={true}
                            type="text"
                            style={{ borderColor: prosLimitError ? '#f51e14' : null }}
                          >
                          </textarea>
                          {prosLimitError && (<div style={{ color: '#f51e14', fontSize: 12, marginLeft: 15 }}>Pros cannot be greater than 2000 characters.</div>)}
                        </>
                      )}
                    </div>
                  </div>
                  {
                    <div className={classes.column}>
                      <div className={classes.header_cons}>
                        <h5>Cons</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setShowInput("cons");
                            setConsLimmitError(false);
                            setConsMigitationLimitError(false);
                            setEditConsLimitError(false)
                            setConsMigitationLimitError(false)
                          }}
                          disabled={readOnly}
                        >
                          <ConsPlusIcon /> Add Cons
                        </button>
                      </div>
                      {cons?.map((p, index) => {
                        const content = (
                          <div key={p?.id} className={classes.item}>
                            <button
                              type="button"
                              onClick={async () => {
                                // await setShowInput("cons");
                                await setEditConsInput({ index, id: p?.id });
                                consRef.current.value = p?.context;
                                // mitigationRef.current.value = p?.mitigation || "";
                                // setProsCons(["cons", p.id]);
                              }}
                              disabled={readOnly}
                            >
                              EDIT
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                setCons(cons?.filter((pd) => pd?.id !== p?.id));
                                removeCons(p?.id);
                              }}
                              disabled={readOnly}
                            >
                              DELETE
                            </button>
                            {!p.mitigation && (
                              <button
                                type="button"
                                onClick={() => {
                                  setAddMitigation(p?.id);
                                }}
                                disabled={readOnly}
                              >
                                ADD MITIGATION
                              </button>
                            )}
                            {p.mitigation && (
                              <button
                                type="button"
                                onClick={async () => {
                                  await setAddMitigation(p?.id);
                                  mitigationRef.current.value = p?.mitigation;
                                }}
                                disabled={readOnly}
                              >
                                EDIT MITIGATION
                              </button>
                            )}
                            {p?.mitigation && (
                              <button
                                type="button"
                                disabled={readOnly}
                                onClick={() => {
                                  dispatch(
                                    UpdateCons(
                                      {
                                        // context: con.context,
                                        mitigation: "",
                                      },
                                      p?.id,
                                      activeOptionCriteria?.id
                                    )
                                  );
                                  // await Axios.patch(
                                  //   `${apiBaseURL}api/v1/decision_matrix/cons/${p.id}/`,
                                  //   {
                                  //     // context: con.context,
                                  //     mitigation: "",
                                  //   }
                                  // );

                                  let index = cons.findIndex(
                                    (c) => c?.id === p?.id
                                  );
                                  let consCopy = [...cons];

                                  consCopy[index] = {
                                    ...consCopy[index],
                                    mitigation: "",
                                  };
                                  setCons([...consCopy]);
                                }}
                              >
                                DELETE MITIGATION
                              </button>
                            )}
                          </div>
                        );

                        return (
                          <div className={classes.consMitigationWrapper}>
                            <div key={p?.id} className={classes.prosWrapper}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "auto",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div className={classes.pros}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <div className={classes.details}>
                                      <div>{index + 1}.</div>

                                      {editConsInput?.index === index &&
                                        editConsInput?.id === p?.id ? (
                                        <div>
                                          <textarea
                                            rows="1"
                                            className={classes.editPros}
                                            onBlur={() => {
                                              editCons(p?.id);
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.keyCode === 13) {
                                                editCons(p?.id);
                                              }
                                            }}
                                            onChange={(e) => {
                                              setLocalConsValue(e?.target?.value);
                                              localUpdateCons(e?.target?.value);
                                            }}
                                            ref={consRef}
                                            placeholder={"Enter a con"}
                                            autoFocus={true}
                                            type="text"
                                            required={true}
                                            style={{ borderColor: editConsInput?.index === index && editConsInput?.id === p?.id && editConsLimitError ? '#f51e14' : null }}
                                          >
                                          </textarea>
                                          {editConsInput?.index === index && editConsInput?.id === p?.id && editConsLimitError && (<div style={{ color: '#f51e14', fontSize: 12 }}>Cons cannot be greater than 2000 characters.</div>)}
                                        </div>
                                      ) : (
                                        <span className={classes.context}>
                                          {p.context}
                                        </span>
                                      )}
                                    </div>
                                    <ActionItem>
                                      <Sorting>
                                        <Dot
                                          grey={
                                            p?.priority == 0 ||
                                            p?.priority === null
                                          }
                                          green={p?.priority == 3}
                                          red={p?.priority == 1}
                                          orange={p?.priority == 2}
                                        ></Dot>
                                        <SmallText>
                                          {((p?.priority == 0 ||
                                            p?.priority === null) &&
                                            "None") ||
                                            (p?.priority == 1 && "Low") ||
                                            (p?.priority == 2 && "Medium") ||
                                            (p?.priority == 3 && "High")}
                                        </SmallText>
                                      </Sorting>
                                      <ButtonWrapper>
                                        <Button
                                          onClick={() =>
                                            handleClick(
                                              "increase",
                                              "con",
                                              p?.id,
                                              p?.priority == "null"
                                                ? 1
                                                : p?.priority
                                            )
                                          }
                                        >
                                          <ArrowUp />
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleClick(
                                              "decrease",
                                              "con",
                                              p?.id,
                                              p?.priority == "null"
                                                ? 0
                                                : p?.priority
                                            )
                                          }
                                        >
                                          <DownArrow />
                                        </Button>
                                      </ButtonWrapper>
                                    </ActionItem>
                                    {p.option_criteria && !readOnly && (
                                      <div
                                        className={classes.popover_container}
                                      >
                                        <Popover
                                          content={content}
                                          onClick={(e) => {
                                            e?.stopPropagation();
                                          }}
                                          trigger="focus"
                                          overlayClassName={classes.dropdown}
                                        >
                                          <EllipsisOutlined
                                            rotate={90}
                                          // style={{ marginBottom: "5px" }}
                                          />
                                        </Popover>
                                      </div>
                                    )}
                                  </div>
                                  <Mitigation
                                    setCons={setCons}
                                    cons={cons}
                                    setAddMitigation={setAddMitigation}
                                    addMitigation={addMitigation}
                                    con={p}
                                    editCons={editCons}
                                    mitigationRef={mitigationRef}
                                    mitigationLimitError={editMitigationLimitError}
                                    setEditMitigationLimitError={setEditMitigationLimitError}
                                  />
                                </div>
                              </div>

                              <div></div>
                              {/*<hr className={classes.br}/>*/}
                            </div>
                            {/* <Mitigation
                              setCons={setCons}
                              cons={cons}
                              setAddMitigation={setAddMitigation}
                              addMitigation={addMitigation}
                              con={p}
                              editCons={editCons}
                              mitigationRef={mitigationRef}
                            /> */}
                          </div>
                        );
                      })}
                      {showInput !== "pros" && (
                        <div className={classes.buttonAdd}>
                          <button
                            type="button"
                            className={classes.button}
                            onClick={() => {
                              setShowInput("cons");
                            }}
                            disabled={readOnly}
                          >
                            <PlusOutlined style={{ color: "#ff0050" }} />
                            <span style={{ color: "#ff0050" }}>{"Add"}</span>
                          </button>
                        </div>
                      )}
                      {showInput === "cons" && (
                        <form
                          onBlur={event => {
                            if (consRef.current && consRef.current.contains(event.relatedTarget))
                              return false;
                            else if (mitigationRef.current && mitigationRef.current.contains(event.relatedTarget))
                              return false;

                            addCons();
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              addCons();
                            }
                          }}
                          style={{ width: '100%' }}
                        >
                          <textarea
                            rows="1"
                            className={classes.inputCons}
                            onBlur={(e) => {
                              e?.preventDefault();
                            }}
                            onChange={(e) => {
                              setLocalConsValue(e?.target?.value);
                              localUpdateCons(e?.target?.value);
                            }}
                            ref={consRef}
                            placeholder={"Enter a con"}
                            required={true}
                            autoFocus={true}
                            type="text"
                            style={{ borderColor: consLimmitError ? '#f51e14' : null }}
                          >
                          </textarea>
                          {consLimmitError && (<div style={{ color: '#f51e14', fontSize: 12, marginLeft: 15 }}>Cons cannot be greater than 2000 characters.</div>)}
                          {localMitigationValue && !localConsValue && (
                            <div className={classes.consrequired}>
                              Cons is required
                            </div>
                          )}
                          <textarea
                            rows="1"
                            onBlur={(e) => {
                              e?.preventDefault();
                            }}
                            onClick={(e) => e?.stopPropagation()}
                            onChange={(e) => {
                              setLocalMitigationValue(e?.target?.value);
                              localUpdateMitigation(e?.target?.value);
                            }}
                            ref={mitigationRef}
                            value={mitigationRef?.curent?.value}
                            placeholder={"Enter a mitigation (Optional)"}
                            className={classes.inputMitigation}
                            type="text"
                            style={{ borderColor: consMigitationLimitError ? '#f51e14' : null, marginBottom: consMigitationLimitError ? '7px' : null }}
                          >
                          </textarea>
                          {consMigitationLimitError && (<div style={{ color: '#f51e14', fontSize: 12, marginLeft: 15 }}>Mitigation cannot be greater than 2000 characters.</div>)}
                        </form>
                      )}
                    </div>
                  }
                </div>
              </>
            ) : (
              loadingImg()
            )}
          </div>
        )}
        {width <= 767 && (
          <div>
            {showCompare ? (
              <div className={classes.compareMobile}>
                <div className={classes.prosConsTitleWrp}>
                  <div
                    onClick={() => setIsProsMob(true)}
                    className={classes.headerMobile}
                    style={{ background: isProsMob && "#61b731" }}
                  >
                    <span>Pros</span>
                  </div>
                  <div
                    onClick={() => setIsProsMob(false)}
                    className={classes.headerMobile}
                    style={{ background: !isProsMob && "#ff0050" }}
                  >
                    <span>Cons</span>
                  </div>
                </div>
                {isProsMob && (
                  <div className={classes.columnMobile}>
                    {pros.map((p, index) => {
                      const content = (
                        <div key={p?.id} className={classes.itema}>
                          <button
                            type="button"
                            onClick={async () => {
                              await setShowInput("pros");
                              prosRef.current.value = p?.context;
                              // await setPros(pros.filter(pd => pd.id !== p.id))
                              setProsCons(["pros", p?.id]);
                            }}
                          >
                            EDIT
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setPros(pros?.filter((pd) => pd?.id !== p?.id));
                              removePros(p?.id);
                            }}
                          >
                            DELETE
                          </button>
                        </div>
                      );
                      return (
                        <div
                          key={p?.id || p?.name}
                          className={classes.prosWrapperMobile}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={classes.prosMobile}>
                              <div>{index + 1}.</div>
                              <span>{p?.context}</span>
                            </div>
                            {p?.option_criteria && !readOnly && (
                              <div>
                                <Popover
                                  content={content}
                                  onClick={(e) => {
                                    e?.stopPropagation();
                                  }}
                                  trigger="focus"
                                >
                                  <EllipsisOutlined
                                    rotate={90}
                                    style={{ marginBottom: "5px" }}
                                  />
                                </Popover>
                              </div>
                            )}
                          </div>

                          <hr className={classes.brMobile} />
                        </div>
                      );
                    })}
                    {showInput !== "pros" && (
                      <button
                        type="button"
                        className={classes.buttonAddMobile}
                        onClick={() => {
                          setShowInput("pros");
                          // setPros([...pros, {context: '', id: new Date().getTime()}])
                        }}
                        disabled={readOnly}
                      >
                        <PlusOutlined style={{ color: "#61b731" }} />
                        <span style={{ color: "#61b731" }}>
                          {updaterProsCons[0] === "pros" ? "Edit" : "Add a pro"}
                        </span>
                      </button>
                    )}
                    {showInput === "pros" && (
                      <input
                        className={classes.inputMobile}
                        onBlur={() => {
                          addPros();
                          // setShowInput('')
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            addPros();
                            // setShowInput('')
                          }
                        }}
                        onChange={(e) => localUpdatePros(e.target.value)}
                        ref={prosRef}
                        placeholder={"Enter a pro"}
                        autoFocus={true}
                        type="text"
                      />
                    )}
                  </div>
                )}
                {!isProsMob && (
                  <div className={classes.columnMobile}>
                    {cons?.map((p, index) => {
                      const content = (
                        <div key={p?.id} className={classes.item}>
                          <button
                            type="button"
                            onClick={async () => {
                              await setShowInput("cons");
                              consRef.current.value = p?.context;
                              mitigationRef.current.value = p?.mitigation || "";
                              setProsCons(["cons", p?.id]);
                            }}
                          >
                            EDIT
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setCons(cons?.filter((pd) => pd?.id !== p?.id));
                              removeCons(p?.id);
                            }}
                          >
                            DELETE
                          </button>
                          {!p?.mitigation && (
                            <button
                              type="button"
                              onClick={() => {
                                setAddMitigation(p?.id);
                              }}
                            >
                              ADD MITIGATION
                            </button>
                          )}
                          {p?.mitigation && (
                            <button
                              type="button"
                              onClick={async () => {
                                await setAddMitigation(p?.id);
                                mitigationRef.current.value = p?.mitigation;
                              }}
                            >
                              EDIT MITIGATION
                            </button>
                          )}
                          {p?.mitigation && (
                            <button
                              type="button"
                              onClick={() => {
                                dispatch(
                                  UpdateCons(
                                    {
                                      mitigation: "",
                                    },
                                    p?.id,
                                    activeOptionCriteria?.id
                                  )
                                );

                                let index = cons?.findIndex(
                                  (c) => c?.id === p?.id
                                );
                                let consCopy = [...cons];

                                consCopy[index] = {
                                  ...consCopy[index],
                                  mitigation: "",
                                };
                                setCons([...consCopy]);
                              }}
                            >
                              DELETE MITIGATION
                            </button>
                          )}
                        </div>
                      );

                      return (
                        <div className={classes.consMitigationWrapperMobile}>
                          <div
                            key={p?.id}
                            className={classes.prosWrapperMobile}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div className={classes.prosMobile}>
                                <div>{index + 1}.</div>
                                <span>{p?.context}</span>
                              </div>
                              {p?.option_criteria && !readOnly && (
                                <div>
                                  <Popover
                                    content={content}
                                    onClick={(e) => {
                                      e?.stopPropagation();
                                    }}
                                    trigger="focus"
                                  >
                                    <EllipsisOutlined
                                      rotate={90}
                                      style={{ marginBottom: "5px" }}
                                    />
                                  </Popover>
                                </div>
                              )}
                            </div>
                            <div></div>
                            {/*<hr className={classes.br}/>*/}
                          </div>
                          <Mitigation
                            setCons={setCons}
                            cons={cons}
                            setAddMitigation={setAddMitigation}
                            addMitigation={addMitigation}
                            con={p}
                            editCons={editCons}
                            mitigationRef={mitigationRef}
                          />
                        </div>
                      );
                    })}
                    {showInput !== "cons" && (
                      <button
                        type="button"
                        className={classes.buttonAddMobile}
                        onClick={() => {
                          setShowInput("cons");
                          // setIsFormSubmite(false)
                          // setCons([...cons, {context: '', id: new Date().getTime()}])
                        }}
                        disabled={readOnly}
                      >
                        <PlusOutlined style={{ color: "#ff0050" }} />
                        <span style={{ color: "#ff0050" }}>{"Add a con"}</span>
                      </button>
                    )}
                    {showInput === "cons" && (
                      <form
                        onBlur={() => {
                          mitigationRef?.current?.focus();
                        }}
                        onKeyDown={(e) => {
                          if (e?.keyCode === 13) {
                            addCons();
                          }
                        }}
                      >
                        <input
                          className={classes.inputMobile}
                          onBlur={(e) => {
                            e?.preventDefault();
                          }}
                          onChange={(e) => localUpdateCons(e?.target?.value)}
                          ref={consRef}
                          placeholder={"Enter a con"}
                          required={true}
                          autoFocus={true}
                          type="text"
                        />
                        <input
                          onBlur={(e) => {
                            e?.preventDefault();
                            addCons();
                          }}
                          onClick={(e) => e?.stopPropagation()}
                          onChange={(e) =>
                            localUpdateMitigation(e?.target?.value)
                          }
                          ref={mitigationRef}
                          value={mitigationRef?.curent?.value}
                          placeholder={"Enter a mitigation (Optional)"}
                          className={classes.inputMitigationMobile}
                          type="text"
                        />
                      </form>
                    )}
                  </div>
                )}
              </div>
            ) : (
              loadingImg()
            )}
          </div>
        )}
      </>
      {/* )} */}
    </div>
  );
};

export { Compare };
const ActionItem = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0.1rem;
`;
const Sorting = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ grey, green, red, orange }) =>
    (grey && "#8e97a3") ||
    (green && "#4b8120") ||
    (red && "#f00001") ||
    (orange && "#feab3c")};
  margin: 0;
  padding: 0;
`;
const SmallText = styled.p`
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0;
  padding-right: 3px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  padding-left: 4px;
  transform: translateY(2px);
`;
const Button = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Icon = styled.img`
  height: 8px;
`;
