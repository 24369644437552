import styled, { css } from 'styled-components';
import { Form } from 'react-bootstrap';

import TextBoxLabel from '../../../atoms/TextBoxLabel';
import Button from '../../Button';
import CheckIcon from '../../../assets/images/auth/check.svg';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100% - 60px);

  ${({ $modal }) => $modal && css`
    height: 100%;
  `}

  ${({ theme }) => theme.max("lg")`
    min-height: calc(100vh - 60px);
  `}

  ${({ theme }) => theme.max("lg")`
    flex-direction: column;
    overflow: hidden;
  `}
`;

export const Side = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ $modal }) => $modal ? css`
    box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.25);
  ` : css`
    ${({ theme }) => theme.max("lg")`
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    `}
  `}

  ${({ theme }) => theme.max("lg")`
    overflow-x: scroll;
    z-index: 2;

    ::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 202px;

  ${({ $modal }) => $modal && css`
    padding-top: 66px;
    justify-content: initial;
  `}

  > div {
    display: flex;
    flex-direction: column;
  }

  ${({ theme }) => theme.max("lg")`
    justify-content: space-between;
    flex-direction: row;
    padding-top: 0;
    padding-right: 20px;
    width: auto;
  `}

  ${({ theme }) => theme.max("xs")`
    width: min-content;
  `}
`;

export const Link = styled.a`
  width: 100%;
  padding: 9px 0;
  color: #8A8A8A;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  margin: 4px 0;
  cursor: pointer;

  ${({ theme }) => theme.max("lg")`
    width: initial;
  `}

  ::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: -26px;
    margin-top: 2px;
    background: #1271A6;
    opacity: 0;
    border-radius: 50%;

    ${({ theme }) => theme.max("lg")`
      left: initial;
      right: 0;
      margin-top: 3px;
    `}
  }

  ${({ $modal }) => $modal && css`
    text-align: center;

    ::before {
      content: none;
    }

    ::after {
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      right: 0;
      top: 0;
      background: linear-gradient(161.19deg, #1263DE 18.35%, #448FFF 74.45%);
      opacity: 0;
    }

    ${({ theme }) => theme.max("lg")`
      ::after {
        height: 3px;
        width: 100%;
        top: initial;
        bottom: -4px;
        left: 0;
        right: initial;
      }
    `}
  `}

  ${({ theme }) => theme.max("lg")`
    white-space: nowrap;
    padding: 9px 16px;
  `}

  ${({ $disabled }) => $disabled && css`
    cursor: initial;
    color: #8A8A8A;

    &:hover {
      color: #8A8A8A;
      text-decoration: none;
    }
  `}

  ${({ $active }) => $active && css`
    color: #000;
    font-weight: 600;

    ::before,
    ::after {
      opacity: 1;
    }
  `}
`;

export const SpinWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 8px 14px 24px;

  ${({ theme }) => theme.max("lg")`
    overflow: hidden;
  `}
`;

export const TextWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  padding: 0 0 16px;

  ::before,
  ::after {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.99) 29.69%, rgba(255, 255, 255, 0) 100%);
    height: 62px;
    width: calc(100% - 20px);
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
  }

  ::before {
    top: 0;
    z-index: 1;
  }

  ::after {
    transform: rotate(180deg);
    bottom: 0;
  }

  &.scroll-one {
    ::before {
      opacity: 1;
    }
  }

  &.scroll-two {
    ::after {
      opacity: 1;
    }
  }

  ${({ theme }) => theme.max("lg")`
    // padding: 0;

    ::before,
    ::after {
      width: 100%;
    }
  `}
`;

export const Text = styled.div`
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-right: 20px;
  scrollbar-width: auto;
  scrollbar-color: #c1c6cc #F5F5F5;

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: #f4f5f6;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid #f4f5f6;
    background: #c1c6cc;
    border-radius: 16px;
  }

  ${({ theme }) => theme.max("lg")`
    scrollbar-width: none;
    padding-top: 20px;
    padding-bottom: 20px;
    width: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const Error = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: -16px;
`;

export const Footer = styled.div`
  padding: 0 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.max("xs")`
    flex-direction: column;
  `}
`;

export const LabelCheckboxText = styled(TextBoxLabel)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: #4C545B;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  flex: 1;
  position: relative;
  top: 3px;

  ${({ theme }) => theme.max("xs")`
    top: 0;
  `}
`;

export const Checkbox = styled(Form.Check)`
  ::after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #B7BABB;
    background: #fff url(${CheckIcon}) center/16px no-repeat;
    margin-right: 12px;
    transition: background .3s;
  }

  input {
    width: 0;
    height: 0;
    visibility: hidden;
    position: fixed;
    overflow: hidden;

    &:checked + ::after {
      background: blue;
    }

    ${({ theme }) => theme.max("sm")`
      margin-top: 6px;
    `}
  }

  ${({ checked }) => checked && css`
    ::after {
      box-shadow: none;
      background: #1689CA url(${CheckIcon}) center/16px no-repeat;
    }
  `}
`;

export const CheckboxLabel = styled(Form.Label)`
  display: flex;
  padding-left: 0;
  padding-right: 20px;

  &.shake-it-off {
    animation: shake-it-off 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  ${({ theme }) => theme.max("xs")`
    margin-bottom: 14px;
  `}
`;

export const ActionButton = styled(Button)`
  width: 94px;
  height: initial;
  background: #1271A6;
  padding: 10px 16px;
  border-radius: 4px;
  color: ${({ color }) => color};
  transition: all .3s;

  ${({ theme }) => theme.max("xs")`
    width: 100%;
  `}

  &:disabled {
    opacity: 0.5;
  }
`;
