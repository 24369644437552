import React, { useEffect } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";

// Screens
// import Dashboard from "scenes/Dashboard";
import ProjectPlanner from "scenes/ProjectPlanner";
import PlanDetails from "scenes/PlanDetails";
import { } from "scenes/DecisionMatrix";
// import Login from 'scenes/AuthScenes/Login'
// import Signup from 'scenes/AuthScenes/Signup'
// import ForgotPass from 'scenes/AuthScenes/ForgotPass'
// import ResetPass from 'scenes/AuthScenes/ResetPass'
import Profile from "scenes/Profile";
// import Settings from 'scenes/Settings'

// Auth
import Landing from "scenes/AuthScenes/Landing";

import SignIn from './scenes/AuthScenes/SignIn';
import SignUp from './scenes/AuthScenes/SignUp';

import ForgotPassword from "scenes/AuthScenes/ForgotPassword";
import ResetPassword from "scenes/AuthScenes/ResetPassword";
import ForgotPasswordSuccess from "scenes/AuthScenes/ForgotPasswordSuccess";
import Verification from "scenes/AuthScenes/Verification";

// Mission Statement
import InitialSetup from "scenes/MissionStatement";
import SetupJourney from "scenes/MissionStatement/steps/SelectJourney";
import SetupAreas from "scenes/MissionStatement/steps/SelectAreas";
import PrioritizeAreas from "scenes/MissionStatement/steps/PrioritizeAreas";
import PrioritizeCategories from "scenes/MissionStatement/steps/Categories";
import Goals from "scenes/MissionStatement/steps/Goals";
import { AddSubCategoryForm } from "scenes/MissionStatement/components/Forms/AddSubCategoryForm";
import AddGoalForm from "scenes/MissionStatement/components/Forms/AddGoalForm/AddGoalForm";

// Components
import { PrivateRoute, GuestRoute } from "components/RouteWrapper";
import {
  checkAuth
} from "modules/actions/AuthActions";
import { useDispatch } from "react-redux";
import {
  // CreateDecisionForm,
  DecisionDetails,
  DecisionMatrix,
  // DecisionMatrixLayout,
} from "scenes/DecisionMatrix";
import { OptionDetail } from "scenes/DecisionMatrix/steps/optionDetail/optionDetail";
// import { getToken } from "api/axios";
import {
  CreateDecisions
} from "scenes/DecisionMatrix/steps/CreateDecision/CreateDecision";
import { EditDecision } from "scenes/DecisionMatrix/steps/EditDecision/EditDecision";
import { useProject } from "./utils/utility";
import OptionComparison from "scenes/DecisionMatrix/steps/optionComparison/optionComparison";

import { FORGOT_PASSWORD_PATH, FORGOT_PASSWORD_SUCCESS_PATH, RESET_PASSWORD_PATH, SIGN_IN_PATH, SIGN_UP_PATH, OTP_PATH } from "utils/routes";
import { ACCESS_REQUESTED, ALLOCATOR_TOOL, DECISION_COACH, PROJECT_PLANNER, REQUEST_ACCESS, SIGN_UP_INVITE } from "./utils/routes";
import Invite from "./scenes/AuthScenes/Invite";
import RequestAccess from "./scenes/AuthScenes/RequestAccess";
import AccessRequested from "./scenes/AuthScenes/AccessRequested";

function generateRoutesForApps(basePaths, component, extraPath = '') {
  return basePaths.map(basePath => {
    const fullPath = extraPath ? `${basePath}/${extraPath}` : basePath;
    return (
      <Route
        key={fullPath}
        path={fullPath}
        element={<PrivateRoute>{React.createElement(component)}</PrivateRoute>}
      />
    );
  });
}

const MainRoutes = () => {
  useProject();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  // Autologin
  useEffect(() => {
    function handleWindowFocus() {
      dispatch(checkAuth({ silent: true }));
    }

    if (process.env.NODE_ENV !== 'development')
      window.addEventListener("focus", handleWindowFocus);

    return () => {
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, []);

  const appPaths = [DECISION_COACH, ALLOCATOR_TOOL];
  const routes = [
    ...generateRoutesForApps(appPaths, DecisionMatrix),
    ...generateRoutesForApps(appPaths, DecisionDetails, ':decisionId'),
    ...generateRoutesForApps(appPaths, CreateDecisions, 'create-decision'),
    ...generateRoutesForApps(appPaths, EditDecision, 'edit-decision/:decisionId'),
    ...generateRoutesForApps(appPaths, OptionDetail, ':decisionId/option/:optionId'),
    ...generateRoutesForApps(appPaths, OptionComparison, ':decisionId/option-comparison'),

    ...generateRoutesForApps([...appPaths, PROJECT_PLANNER], Profile, 'profile')
  ];

  // const path = window.location.pathname;

  /* useEffect(() => {
    dispatch(());
  }, []); */

  /* useEffect(() => {
    if (token && !path.includes("reset-password")) {
      Cookies.set("lmos-token", token, 14);
      dispatch(getUserProfile(token));
    }
  }, [token, path]); */

  /* useEffect(() => {
    if (!isLoggedIn) return;
    dispatch(getUserProfile(isLoggedIn));
  }, [isLoggedIn]); */

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     dispatch(getUserFromLocalStorage());
  //   }
  // }, [isLoggedIn, path]);

  return (
    <Routes>
      {/* <Route index element={<Navigate to="/sign-in" replace={true} />} /> */}

      {/* Project Planner */}
      <Route
        exact
        path="/project-planner"
        element={
          <PrivateRoute>
            <ProjectPlanner />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/project-planner/plan-details/:id/*"
        element={
          <PrivateRoute>
            <PlanDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={"/shared-project/:id"}
        element={
          <PlanDetails />
        }
      />

      {/* Decision matrix */}

      <Route
        path={"decision-matrix"}
        element={<Navigate to="/decision-manager" replace={true} />}
      />
      <Route
        path={"decision-matrix/:decisionId"}
        element={<Navigate to="/decision-manager" replace={true} />}
      />
      <Route
        path={"decision-matrix/edit-decision/:decisionId"}
        element={<Navigate to="/decision-manager" replace={true} />}
      />
      <Route
        path={"decision-matrix/:decisionId/option/:optionId"}
        element={<Navigate to="/decision-manager" replace={true} />}
      />

      <Route
        path={"shared-decision/:decisionId"}
        element={
          // <PrivateRoute>
          <DecisionDetails />
          // </PrivateRoute>
        }
      />

      <Route
        path={`${ALLOCATOR_TOOL}/shared/:decisionId/`}
        element={
          <PrivateRoute>
            <DecisionDetails />
          </PrivateRoute>
        }
      />

      {routes}

      {/* Mission Statement */}
      <Route
        path="mission-statement"
        element={
          <PrivateRoute>
            <InitialSetup />
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to="journey" replace={true} />} />
        <Route path="journey" element={<SetupJourney />} />
        <Route path="areas" element={<SetupAreas />} />
        <Route path="prioritize-areas" element={<PrioritizeAreas />} />
        <Route path="categories" element={<PrioritizeCategories />}>
          <Route path="add-category" element={<AddSubCategoryForm />} />
          <Route path="add-subcategory" element={<AddSubCategoryForm />} />
        </Route>
        <Route path="goals" element={<Goals />}>
          <Route path="set-goal" element={<AddGoalForm />} />
        </Route>
      </Route>

      {/* Shared */}
      {/* Todo: refactor maybe needed */}
      {/* {[
        "/project-planner/profile",
        "/decision-manager/profile",
        "/mission-statement/profile",
      ].map((path, index) => {
        return (
          <Route
            path={path}
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
            key={index}
          />
        );
      })} */}

      {/* Auth */}
      <Route path="/" exact element={
        <GuestRoute>
          <Landing />
        </GuestRoute>
      } />


      <Route path={SIGN_UP_INVITE} exact element={
        <GuestRoute>
          <Invite />
        </GuestRoute>
      } />

      <Route path={REQUEST_ACCESS} exact element={
        <PrivateRoute>
          <RequestAccess />
        </PrivateRoute>
      } />

      <Route path={ACCESS_REQUESTED} exact element={
        <PrivateRoute>
          <AccessRequested />
        </PrivateRoute>
      } />

      <Route path={SIGN_IN_PATH} exact element={
        <GuestRoute>
          <SignIn />
        </GuestRoute>
      } />

      <Route path={SIGN_UP_PATH} exact element={
        <GuestRoute>
          <SignUp />
        </GuestRoute>
      } />

      <Route path={FORGOT_PASSWORD_PATH} exact element={
        <GuestRoute>
          <ForgotPassword />
        </GuestRoute>
      } />

      <Route path={FORGOT_PASSWORD_SUCCESS_PATH} exact element={
        <GuestRoute>
          <ForgotPasswordSuccess />
        </GuestRoute>
      } />

      <Route path={RESET_PASSWORD_PATH} exact element={
        <GuestRoute>
          <ResetPassword />
        </GuestRoute>
      } />

      <Route path={OTP_PATH} exact element={
        <GuestRoute>
          <Verification />
        </GuestRoute>
      } />
    </Routes>

  );
};

export default MainRoutes;
